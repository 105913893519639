import { Delete } from "@mui/icons-material";
import FlipIcon from "@mui/icons-material/Flip";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useContext } from "react";
import { jobStatuses } from "../../shared/jobStatuses";
import { AuthContext } from "../../store/auth-context";
import { MessageContext } from "../../store/message-context";

const Icon = ({ params, title, jobIcon, isDisabled, action, actionParam }) => {
  const authCtx = useContext(AuthContext);
  const messageCtx = useContext(MessageContext);
  const user = authCtx.userDetails;

  const getIconFromName = (iconName) => {
    switch (iconName) {
      case "TaskAltIcon":
        return <TaskAltIcon />;
      case "RestorePageIcon":
        return <RestorePageIcon />;
      case "RotateRight":
        return <Delete />;
      case "FlipIcon":
        return <FlipIcon />;
      default:
        return <ToggleOnIcon />;
    }
  };
  const icon = getIconFromName(jobIcon);

  const doAction = (actionName, actionParameter) => {
    switch (actionName) {
      case "delete":
        deleteJobFiles(actionParameter);
        break;
      case "flip":
        flip(actionParameter);
        break;
      case "requestCutRepl":
        requestJobItemReplacement(149); // 149 = Recut\Miscellaneous
        break;
      case "deleteCut":
        deleteJobItem();
        break;
      default:
        updateStatus(actionParameter);
        break;
    }
  };

  const updateJob = (job) => {
    authCtx
      .request("PATCH", `${process.env.REACT_APP_Prep_Api_Host}Job/`, job)
      .then((response) => {
        if (response.data === 1) {
          // Update the job status to reflect the change in data.
          params.row.status = job.status;
        } else {
          messageCtx.showMessage(
            "error",
            `Error retrieving jobs: ${response.data}`
          );
        }
      });
  };

  const updateStatus = (statusId) => {
    const updatedStatusId = !isNaN(statusId)
      ? Number(statusId)
      : params.row.status;
    updateJob(Object.assign({}, params.row, { status: updatedStatusId }));
  };

  const deleteJobFiles = () => {
    authCtx
      .request(
        "DELETE",
        `${process.env.REACT_APP_Prep_Api_Host}history/deletebyjob/${params.row.id}`
      )
      .then((response) => {
        updateStatus(jobStatuses.DELETE_AND_REGEN_FILE);
        messageCtx.showMessage(
          "info",
          `Deleted files and regened for job ${params.row.id}`
        );
      });
  };

  const requestJobItemReplacement = (replacementReasonID) => {
    if (!user["admin/prepUserId"]) {
      messageCtx.showMessage(
        "error",
        `User doesn't have a valid MemberID attached to their Auth0 account.`
      );
    } else {
      authCtx
        .request(
          "PUT",
          `${process.env.REACT_APP_Prep_Api_Host}jobitem/${params.row.id}/replace?jobGroup=replacement&replacementReasonID=${replacementReasonID}&itemRequestLocation=admin&memberID=${user["admin/prepUserId"]}`
        )
        .then((response) => {
          if (response && response.data) {
            if (response.status === 200) {
              messageCtx.showMessage(
                "info",
                `Replacement cut created in JobID: ${response.data}`
              );
            } else {
              messageCtx.showMessage(
                "error",
                `Replacement failed with status code: ${response.status}`
              );
            }
          }
        });
    }
  };

  const deleteJobItem = () => {
    authCtx
      .request(
        "DELETE",
        `${process.env.REACT_APP_Prep_Api_Host}jobItem/${params.row.id}`
      )
      .then((response) => {
        if (response.status === 200) {
          messageCtx.showMessage("info", `Cut ${params.row.id} deleted.`);
        } else {
          messageCtx.showMessage(
            "error",
            `Delete failed with status code: ${response.status}`
          );
        }
      });
  };

  const flip = (flipTo) => {
    // Update job status to 'Delete and Regen' and set JobDeviceID accordingly
    updateJob(
      Object.assign({}, params.row, {
        status: jobStatuses.DELETE_AND_REGEN_FILE,
        jobDevice: Number(flipTo),
      })
    );
    messageCtx.showMessage("info", `Flipped to ${flipTo}.`);
  };

  return (
    <span>
      <IconButton
        aria-label={title}
        disabled={isDisabled}
        onClick={(e) => doAction(`${action}`, `${actionParam}`)}
      >
        <Tooltip title={title}>{icon}</Tooltip>
      </IconButton>
    </span>
  );
};

export default Icon;
