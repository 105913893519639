import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import PropTypes from "prop-types";
import React, { createContext, useState } from "react";
export const AuthContext = createContext({
  token: "",
  isUserAuthenticated: false,
  userDetails: {},
  logoutUser: () => {},
  loginUser: () => {},
  isInRole: (roleName) => {},
  request: (method, url, data) => {},
});

function AuthContextProvider({ children }) {
  const [authToken, setAuthToken] = useState();
  const {
    loginWithRedirect,
    logout,
    user,
    isAuthenticated,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();

  function loginUser() {
    loginWithRedirect();
  }

  function logoutUser() {
    logout();
  }

  /// Function to check roles.
  /// <param name="roles">Array of roles to be checked</param>
  function isInRole(roles) {
    // Quick check for super users. There is no reason to check roles for these users.
    if (
      user["admin/roles"].includes("Admin") ||
      user["admin/roles"].includes("Developer")
    ) {
      return true;
    }
    let isInRoles = false;
    for (let i = 0; i < roles.length; i++) {
      isInRoles = user["admin/roles"].includes(roles[i]);
    }
    return isInRoles;
  }

  async function getToken() {
    const authorizationParams = {
      audience: process.env.REACT_APP_Auth0_Audience,
      scope:
        "order:read order:create job:read job:update job:create job:delete partner:read product:read product:update store:read store:create store:update payment:read garment:read garment:create garment:update garment:delete email:send redis:flush",
    };
    try {
      const authToken = await getAccessTokenSilently({
        authorizationParams,
      });
      setAuthToken(authToken);
      return authToken;
    } catch (error) {
      const authToken = await getAccessTokenWithPopup({
        authorizationParams,
      });
      setAuthToken(authToken);
      return authToken;
    }
  }

  async function request(method, url, data) {
    const token = await getToken();
    if (token) {
      return await axios({
        method,
        url,
        data,
        headers: { Authorization: `Bearer ${token}` },
      });
    }
  }
  const value = {
    isUserAuthenticated: !!isAuthenticated,
    logoutUser,
    loginUser,
    userDetails: user,
    token: authToken,
    request,
    isInRole,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
AuthContextProvider.propTypes = {
  children: PropTypes.object,
};
export default AuthContextProvider;
