import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import { MuiColorInput } from "mui-color-input";

import React from "react";

function hexToRgb(hex) {
  // Remove the '#' if present
  hex = hex.replace("#", "");

  // Parse hex values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return [r, g, b];
}

function colorDistance(hex1, hex2) {
  const [r1, g1, b1] = hexToRgb(hex1);
  const [r2, g2, b2] = hexToRgb(hex2);

  // Calculate Euclidean distance
  return Math.sqrt((r1 - r2) ** 2 + (g1 - g2) ** 2 + (b1 - b2) ** 2);
}
function findClosestColor(items) {
  if (items.length === 0) return null;

  let lowestItem = items[0];

  for (let i = 1; i < items.length; i++) {
    if (items[i].distance < lowestItem.distance) {
      lowestItem = items[i];
    }
  }

  return lowestItem;
}

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

const colors = [
  {
    hex: "#FFB300",
    name: "Athletic Gold",
  },
  {
    hex: "#181717",
    name: "Black",
  },
  {
    hex: "#6A371C",
    name: "Brown",
  },
  {
    hex: "#770B0C",
    name: "Cardinal",
  },
  {
    hex: "#F5E6BD",
    name: "Cream",
  },
  {
    hex: "#0596C4",
    name: "Cyan",
  },
  {
    hex: "#0F4509",
    name: "Dark Green",
  },
  {
    hex: "#108810",
    name: "Green",
  },
  {
    hex: "#aaaaaa",
    name: "Grey",
  },
  {
    hex: "#FFC9EB",
    name: "Light Pink",
  },
  {
    hex: "#6bc600",
    name: "Lime",
  },
  {
    hex: "#862632",
    name: "Maroon",
  },
  {
    hex: "#1B1640",
    name: "Navy",
  },
  {
    hex: "#FB6A00",
    name: "Orange",
  },
  {
    hex: "#FF4DB8",
    name: "Pink",
  },
  {
    hex: "#4A0C76",
    name: "Purple",
  },
  {
    hex: "#CE0505",
    name: "Red",
  },
  {
    hex: "#0057B8",
    name: "Royal",
  },
  {
    hex: "#C4C4C4",
    name: "Silver",
  },
  {
    hex: "#007080",
    name: "Teal",
  },
  {
    hex: "#B55109",
    name: "Texas Orange",
  },
  {
    hex: "#B9A36A",
    name: "Vegas Gold",
  },
  {
    hex: "#F8F8F8",
    name: "White",
  },
  {
    hex: "#FFEE44",
    name: "Yellow",
  },
];

const pantones = [
  {
    name: "100-c",
    hex: "#f6eb61",
  },
  {
    name: "101-c",
    hex: "#f7ea48",
  },
  {
    name: "102-c",
    hex: "#fce300",
  },
  {
    name: "103-c",
    hex: "#c5a900",
  },
  {
    name: "104-c",
    hex: "#af9800",
  },
  {
    name: "105-c",
    hex: "#897a27",
  },
  {
    name: "7401-c",
    hex: "#f5e1a4",
  },
  {
    name: "7402-c",
    hex: "#ecd898",
  },
  {
    name: "7403-c",
    hex: "#eed484",
  },
  {
    name: "7404-c",
    hex: "#f4da40",
  },
  {
    name: "7405-c",
    hex: "#f2cd00",
  },
  {
    name: "7406-c",
    hex: "#f1c400",
  },
  {
    name: "7407-c",
    hex: "#cba052",
  },
  {
    name: "106-c",
    hex: "#f9e547",
  },
  {
    name: "107-c",
    hex: "#fbe122",
  },
  {
    name: "108-c",
    hex: "#fedb00",
  },
  {
    name: "109-c",
    hex: "#ffd100",
  },
  {
    name: "110-c",
    hex: "#daaa00",
  },
  {
    name: "111-c",
    hex: "#aa8a00",
  },
  {
    name: "112-c",
    hex: "#9c8412",
  },
  {
    name: "113-c",
    hex: "#fae053",
  },
  {
    name: "114-c",
    hex: "#fbdd40",
  },
  {
    name: "115-c",
    hex: "#fdda24",
  },
  {
    name: "116-c",
    hex: "#ffcd00",
  },
  {
    name: "117-c",
    hex: "#c99700",
  },
  {
    name: "118-c",
    hex: "#ac8400",
  },
  {
    name: "119-c",
    hex: "#897322",
  },
  {
    name: "127-c",
    hex: "#f3dd6d",
  },
  {
    name: "128-c",
    hex: "#f3d54e",
  },
  {
    name: "129-c",
    hex: "#f3d03e",
  },
  {
    name: "130-c",
    hex: "#f2a900",
  },
  {
    name: "131-c",
    hex: "#cc8a00",
  },
  {
    name: "132-c",
    hex: "#a07400",
  },
  {
    name: "133-c",
    hex: "#6c571b",
  },
  {
    name: "1205-c",
    hex: "#f8e08e",
  },
  {
    name: "1215-c",
    hex: "#fbd872",
  },
  {
    name: "1225-c",
    hex: "#ffc845",
  },
  {
    name: "1235-c",
    hex: "#ffb81c",
  },
  {
    name: "1245-c",
    hex: "#c69214",
  },
  {
    name: "1255-c",
    hex: "#ad841f",
  },
  {
    name: "1265-c",
    hex: "#886b25",
  },
  {
    name: "120-c",
    hex: "#fbdb65",
  },
  {
    name: "121-c",
    hex: "#fdd757",
  },
  {
    name: "122-c",
    hex: "#fed141",
  },
  {
    name: "123-c",
    hex: "#ffc72c",
  },
  {
    name: "124-c",
    hex: "#eaaa00",
  },
  {
    name: "125-c",
    hex: "#b58500",
  },
  {
    name: "126-c",
    hex: "#9a7611",
  },
  {
    name: "7548-c",
    hex: "#ffc600",
  },
  {
    name: "7549-c",
    hex: "#ffb500",
  },
  {
    name: "7550-c",
    hex: "#d19000",
  },
  {
    name: "7551-c",
    hex: "#b47e00",
  },
  {
    name: "7552-c",
    hex: "#73531d",
  },
  {
    name: "7553-c",
    hex: "#5a4522",
  },
  {
    name: "7554-c",
    hex: "#4b3d2a",
  },
  {
    name: "7555-c",
    hex: "#d29f13",
  },
  {
    name: "7556-c",
    hex: "#b78b20",
  },
  {
    name: "7557-c",
    hex: "#9f7d23",
  },
  {
    name: "7558-c",
    hex: "#967126",
  },
  {
    name: "7559-c",
    hex: "#8f6a2a",
  },
  {
    name: "7560-c",
    hex: "#7d622e",
  },
  {
    name: "7561-c",
    hex: "#6c5d34",
  },
  {
    name: "134-c",
    hex: "#fdd26e",
  },
  {
    name: "135-c",
    hex: "#ffc658",
  },
  {
    name: "136-c",
    hex: "#ffbf3f",
  },
  {
    name: "137-c",
    hex: "#ffa300",
  },
  {
    name: "138-c",
    hex: "#de7c00",
  },
  {
    name: "139-c",
    hex: "#af6d04",
  },
  {
    name: "140-c",
    hex: "#74531c",
  },
  {
    name: "1345-c",
    hex: "#fdd086",
  },
  {
    name: "1355-c",
    hex: "#ffc56e",
  },
  {
    name: "1365-c",
    hex: "#ffb549",
  },
  {
    name: "1375-c",
    hex: "#ff9e1b",
  },
  {
    name: "1385-c",
    hex: "#d57800",
  },
  {
    name: "1395-c",
    hex: "#996017",
  },
  {
    name: "1405-c",
    hex: "#6e4c1e",
  },
  {
    name: "141-c",
    hex: "#f2c75c",
  },
  {
    name: "142-c",
    hex: "#f1be48",
  },
  {
    name: "143-c",
    hex: "#f1b434",
  },
  {
    name: "144-c",
    hex: "#ed8b00",
  },
  {
    name: "145-c",
    hex: "#cf7f00",
  },
  {
    name: "146-c",
    hex: "#a76d11",
  },
  {
    name: "147-c",
    hex: "#715c2a",
  },
  {
    name: "7408-c",
    hex: "#f6be00",
  },
  {
    name: "7409-c",
    hex: "#f0b323",
  },
  {
    name: "7410-c",
    hex: "#fead77",
  },
  {
    name: "7411-c",
    hex: "#e6a65d",
  },
  {
    name: "7412-c",
    hex: "#d38235",
  },
  {
    name: "7413-c",
    hex: "#dc8633",
  },
  {
    name: "7414-c",
    hex: "#c16c18",
  },
  {
    name: "7562-c",
    hex: "#bd9b60",
  },
  {
    name: "7563-c",
    hex: "#d69a2d",
  },
  {
    name: "7564-c",
    hex: "#db8a06",
  },
  {
    name: "7565-c",
    hex: "#cd7925",
  },
  {
    name: "7566-c",
    hex: "#ad6433",
  },
  {
    name: "7567-c",
    hex: "#89532f",
  },
  {
    name: "7568-c",
    hex: "#775135",
  },
  {
    name: "7569-c",
    hex: "#d78825",
  },
  {
    name: "7570-c",
    hex: "#d3832b",
  },
  {
    name: "7571-c",
    hex: "#c67d30",
  },
  {
    name: "7572-c",
    hex: "#b67233",
  },
  {
    name: "7573-c",
    hex: "#a7662b",
  },
  {
    name: "7574-c",
    hex: "#9e6a38",
  },
  {
    name: "7575-c",
    hex: "#835d32",
  },
  {
    name: "712-c",
    hex: "#fcc89b",
  },
  {
    name: "713-c",
    hex: "#fdbe87",
  },
  {
    name: "714-c",
    hex: "#fdaa63",
  },
  {
    name: "715-c",
    hex: "#f68d2e",
  },
  {
    name: "716-c",
    hex: "#ea7600",
  },
  {
    name: "717-c",
    hex: "#d45d00",
  },
  {
    name: "718-c",
    hex: "#be4d00",
  },
  {
    name: "148-c",
    hex: "#fecb8b",
  },
  {
    name: "149-c",
    hex: "#ffc27b",
  },
  {
    name: "150-c",
    hex: "#ffb25b",
  },
  {
    name: "151-c",
    hex: "#ff8200",
  },
  {
    name: "152-c",
    hex: "#e57200",
  },
  {
    name: "153-c",
    hex: "#be6a14",
  },
  {
    name: "154-c",
    hex: "#9b5a1a",
  },
  {
    name: "155-c",
    hex: "#efd19f",
  },
  {
    name: "156-c",
    hex: "#efbe7d",
  },
  {
    name: "157-c",
    hex: "#eca154",
  },
  {
    name: "158-c",
    hex: "#e87722",
  },
  {
    name: "159-c",
    hex: "#cb6015",
  },
  {
    name: "160-c",
    hex: "#a1561c",
  },
  {
    name: "161-c",
    hex: "#603d20",
  },
  {
    name: "1485-c",
    hex: "#ffae62",
  },
  {
    name: "1495-c",
    hex: "#ff8f1c",
  },
  {
    name: "1505-c",
    hex: "#ff6900",
  },
  {
    name: "1525-c",
    hex: "#b94700",
  },
  {
    name: "1535-c",
    hex: "#94450b",
  },
  {
    name: "1545-c",
    hex: "#653819",
  },
  {
    name: "1555-c",
    hex: "#ffb990",
  },
  {
    name: "1565-c",
    hex: "#ffa06a",
  },
  {
    name: "1575-c",
    hex: "#ff7f32",
  },
  {
    name: "1585-c",
    hex: "#ff6a13",
  },
  {
    name: "1595-c",
    hex: "#d86018",
  },
  {
    name: "1605-c",
    hex: "#a65523",
  },
  {
    name: "1615-c",
    hex: "#8b4720",
  },
  {
    name: "162-c",
    hex: "#ffbe9f",
  },
  {
    name: "163-c",
    hex: "#ff9d6e",
  },
  {
    name: "164-c",
    hex: "#ff7f41",
  },
  {
    name: "165-c",
    hex: "#ff671f",
  },
  {
    name: "166-c",
    hex: "#e35205",
  },
  {
    name: "167-c",
    hex: "#be531c",
  },
  {
    name: "168-c",
    hex: "#73381d",
  },
  {
    name: "7576-c",
    hex: "#db864e",
  },
  {
    name: "7577-c",
    hex: "#e07e3c",
  },
  {
    name: "7578-c",
    hex: "#dc6b2f",
  },
  {
    name: "7579-c",
    hex: "#dc582a",
  },
  {
    name: "7580-c",
    hex: "#c05131",
  },
  {
    name: "7581-c",
    hex: "#864a33",
  },
  {
    name: "7582-c",
    hex: "#674736",
  },
  {
    name: "1625-c",
    hex: "#ffa38b",
  },
  {
    name: "1635-c",
    hex: "#ff8d6d",
  },
  {
    name: "1645-c",
    hex: "#ff6a39",
  },
  {
    name: "1655-c",
    hex: "#fc4c02",
  },
  {
    name: "1665-c",
    hex: "#dc4405",
  },
  {
    name: "1675-c",
    hex: "#a9431e",
  },
  {
    name: "1685-c",
    hex: "#833921",
  },
  {
    name: "169-c",
    hex: "#ffb3ab",
  },
  {
    name: "170-c",
    hex: "#ff8674",
  },
  {
    name: "171-c",
    hex: "#ff5c39",
  },
  {
    name: "172-c",
    hex: "#fa4616",
  },
  {
    name: "173-c",
    hex: "#cf4520",
  },
  {
    name: "174-c",
    hex: "#963821",
  },
  {
    name: "175-c",
    hex: "#6b3529",
  },
  {
    name: "7583-c",
    hex: "#c4622d",
  },
  {
    name: "7584-c",
    hex: "#ba5826",
  },
  {
    name: "7585-c",
    hex: "#af5c37",
  },
  {
    name: "7586-c",
    hex: "#9e5330",
  },
  {
    name: "7587-c",
    hex: "#924c2e",
  },
  {
    name: "7588-c",
    hex: "#7b4d35",
  },
  {
    name: "7589-c",
    hex: "#5c4738",
  },
  {
    name: "7590-c",
    hex: "#d4b59e",
  },
  {
    name: "7591-c",
    hex: "#c07d59",
  },
  {
    name: "7592-c",
    hex: "#b15533",
  },
  {
    name: "7593-c",
    hex: "#9d432c",
  },
  {
    name: "7594-c",
    hex: "#7c3a2d",
  },
  {
    name: "7595-c",
    hex: "#6b3d2e",
  },
  {
    name: "7596-c",
    hex: "#5c3d31",
  },
  {
    name: "7597-c",
    hex: "#d14124",
  },
  {
    name: "7598-c",
    hex: "#bd472a",
  },
  {
    name: "7599-c",
    hex: "#b33d26",
  },
  {
    name: "7600-c",
    hex: "#8d3f2b",
  },
  {
    name: "7601-c",
    hex: "#83412c",
  },
  {
    name: "7602-c",
    hex: "#7b4931",
  },
  {
    name: "7603-c",
    hex: "#674230",
  },
  {
    name: "7604-c",
    hex: "#e4d5d3",
  },
  {
    name: "7605-c",
    hex: "#e1bbb4",
  },
  {
    name: "7606-c",
    hex: "#d6938a",
  },
  {
    name: "7607-c",
    hex: "#c26e60",
  },
  {
    name: "7608-c",
    hex: "#a4493d",
  },
  {
    name: "7609-c",
    hex: "#823b34",
  },
  {
    name: "7610-c",
    hex: "#683431",
  },
  {
    name: "7611-c",
    hex: "#ddbcb0",
  },
  {
    name: "7612-c",
    hex: "#ca9a8e",
  },
  {
    name: "7613-c",
    hex: "#bc8a7e",
  },
  {
    name: "7614-c",
    hex: "#a37f74",
  },
  {
    name: "7615-c",
    hex: "#866761",
  },
  {
    name: "7616-c",
    hex: "#6b4c4c",
  },
  {
    name: "7617-c",
    hex: "#583d3e",
  },
  {
    name: "7520-c",
    hex: "#eabeb0",
  },
  {
    name: "7521-c",
    hex: "#c09c83",
  },
  {
    name: "7522-c",
    hex: "#b46a55",
  },
  {
    name: "7523-c",
    hex: "#ab5c57",
  },
  {
    name: "7524-c",
    hex: "#a45248",
  },
  {
    name: "7525-c",
    hex: "#9a6a4f",
  },
  {
    name: "7526-c",
    hex: "#8a391b",
  },
  {
    name: "489-c",
    hex: "#ecc3b2",
  },
  {
    name: "488-c",
    hex: "#ecbaa8",
  },
  {
    name: "487-c",
    hex: "#eaa794",
  },
  {
    name: "486-c",
    hex: "#e8927c",
  },
  {
    name: "485-c",
    hex: "#da291c",
  },
  {
    name: "484-c",
    hex: "#9a3324",
  },
  {
    name: "483-c",
    hex: "#653024",
  },
  {
    name: "176-c",
    hex: "#ffb1bb",
  },
  {
    name: "177-c",
    hex: "#ff808b",
  },
  {
    name: "178-c",
    hex: "#ff585d",
  },
  {
    name: "179-c",
    hex: "#e03c31",
  },
  {
    name: "180-c",
    hex: "#be3a34",
  },
  {
    name: "181-c",
    hex: "#81312f",
  },
  {
    name: "1765-c",
    hex: "#ffa3b5",
  },
  {
    name: "1775-c",
    hex: "#ff8da1",
  },
  {
    name: "1785-c",
    hex: "#f8485e",
  },
  {
    name: "1788-c",
    hex: "#ee2737",
  },
  {
    name: "1795-c",
    hex: "#d22630",
  },
  {
    name: "1805-c",
    hex: "#af272f",
  },
  {
    name: "1815-c",
    hex: "#7c2529",
  },
  {
    name: "1767-c",
    hex: "#fcafc0",
  },
  {
    name: "1777-c",
    hex: "#fb637e",
  },
  {
    name: "1787-c",
    hex: "#f4364c",
  },
  {
    name: "1797-c",
    hex: "#cb333b",
  },
  {
    name: "1807-c",
    hex: "#a4343a",
  },
  {
    name: "1817-c",
    hex: "#643335",
  },
  {
    name: "7618-c",
    hex: "#c66e4e",
  },
  {
    name: "7619-c",
    hex: "#c04c36",
  },
  {
    name: "7620-c",
    hex: "#b7312c",
  },
  {
    name: "7621-c",
    hex: "#ab2328",
  },
  {
    name: "7622-c",
    hex: "#93272c",
  },
  {
    name: "7623-c",
    hex: "#8a2a2b",
  },
  {
    name: "7624-c",
    hex: "#802f2d",
  },
  {
    name: "7625-c",
    hex: "#e1523d",
  },
  {
    name: "7626-c",
    hex: "#c63527",
  },
  {
    name: "7627-c",
    hex: "#a72b2a",
  },
  {
    name: "7628-c",
    hex: "#9e2a2b",
  },
  {
    name: "7629-c",
    hex: "#6d3332",
  },
  {
    name: "7630-c",
    hex: "#633231",
  },
  {
    name: "7631-c",
    hex: "#572d2d",
  },
  {
    name: "7415-c",
    hex: "#e6baa8",
  },
  {
    name: "7416-c",
    hex: "#e56a54",
  },
  {
    name: "7417-c",
    hex: "#e04e39",
  },
  {
    name: "7418-c",
    hex: "#cd545b",
  },
  {
    name: "7419-c",
    hex: "#b04a5a",
  },
  {
    name: "7420-c",
    hex: "#9b2242",
  },
  {
    name: "7421-c",
    hex: "#651d32",
  },
  {
    name: "182-c",
    hex: "#fabbcb",
  },
  {
    name: "183-c",
    hex: "#fc9bb3",
  },
  {
    name: "184-c",
    hex: "#f65275",
  },
  {
    name: "185-c",
    hex: "#e4002b",
  },
  {
    name: "186-c",
    hex: "#c8102e",
  },
  {
    name: "187-c",
    hex: "#a6192e",
  },
  {
    name: "188-c",
    hex: "#76232f",
  },
  {
    name: "196-c",
    hex: "#ecc7cd",
  },
  {
    name: "197-c",
    hex: "#e89cae",
  },
  {
    name: "198-c",
    hex: "#df4661",
  },
  {
    name: "199-c",
    hex: "#d50032",
  },
  {
    name: "200-c",
    hex: "#ba0c2f",
  },
  {
    name: "201-c",
    hex: "#9d2235",
  },
  {
    name: "202-c",
    hex: "#862633",
  },
  {
    name: "189-c",
    hex: "#f8a3bc",
  },
  {
    name: "190-c",
    hex: "#f67599",
  },
  {
    name: "191-c",
    hex: "#ef426f",
  },
  {
    name: "192-c",
    hex: "#e40046",
  },
  {
    name: "193-c",
    hex: "#bf0d3e",
  },
  {
    name: "194-c",
    hex: "#9b2743",
  },
  {
    name: "195-c",
    hex: "#782f40",
  },
  {
    name: "1895-c",
    hex: "#f5b6cd",
  },
  {
    name: "1905-c",
    hex: "#f59bbb",
  },
  {
    name: "1915-c",
    hex: "#ef4a81",
  },
  {
    name: "1925-c",
    hex: "#e0004d",
  },
  {
    name: "1935-c",
    hex: "#c5003e",
  },
  {
    name: "1945-c",
    hex: "#a6093d",
  },
  {
    name: "1955-c",
    hex: "#8a1538",
  },
  {
    name: "705-c",
    hex: "#f5dadf",
  },
  {
    name: "706-c",
    hex: "#f7ced7",
  },
  {
    name: "707-c",
    hex: "#f9b5c4",
  },
  {
    name: "708-c",
    hex: "#f890a5",
  },
  {
    name: "709-c",
    hex: "#ef6079",
  },
  {
    name: "710-c",
    hex: "#e03e52",
  },
  {
    name: "711-c",
    hex: "#cb2c30",
  },
  {
    name: "698-c",
    hex: "#f2d4d7",
  },
  {
    name: "699-c",
    hex: "#f4c3cc",
  },
  {
    name: "700-c",
    hex: "#f2acb9",
  },
  {
    name: "701-c",
    hex: "#e68699",
  },
  {
    name: "702-c",
    hex: "#d25b73",
  },
  {
    name: "703-c",
    hex: "#b83a4b",
  },
  {
    name: "704-c",
    hex: "#9e2a2f",
  },
  {
    name: "203-c",
    hex: "#ecb3cb",
  },
  {
    name: "204-c",
    hex: "#e782a9",
  },
  {
    name: "205-c",
    hex: "#e0457b",
  },
  {
    name: "206-c",
    hex: "#ce0037",
  },
  {
    name: "207-c",
    hex: "#a50034",
  },
  {
    name: "208-c",
    hex: "#861f41",
  },
  {
    name: "209-c",
    hex: "#6f263d",
  },
  {
    name: "210-c",
    hex: "#f99fc9",
  },
  {
    name: "211-c",
    hex: "#f57eb6",
  },
  {
    name: "212-c",
    hex: "#f04e98",
  },
  {
    name: "213-c",
    hex: "#e31c79",
  },
  {
    name: "214-c",
    hex: "#ce0f69",
  },
  {
    name: "215-c",
    hex: "#ac145a",
  },
  {
    name: "216-c",
    hex: "#7d2248",
  },
  {
    name: "7422-c",
    hex: "#f4cdd4",
  },
  {
    name: "7423-c",
    hex: "#e06287",
  },
  {
    name: "7424-c",
    hex: "#e24585",
  },
  {
    name: "7425-c",
    hex: "#b52555",
  },
  {
    name: "7426-c",
    hex: "#a4123f",
  },
  {
    name: "7427-c",
    hex: "#971b2f",
  },
  {
    name: "7428-c",
    hex: "#6a2c3e",
  },
  {
    name: "7632-c",
    hex: "#d6c9ca",
  },
  {
    name: "7633-c",
    hex: "#c4a4a7",
  },
  {
    name: "7634-c",
    hex: "#c16784",
  },
  {
    name: "7635-c",
    hex: "#c63663",
  },
  {
    name: "7636-c",
    hex: "#bc204b",
  },
  {
    name: "7637-c",
    hex: "#912f46",
  },
  {
    name: "7638-c",
    hex: "#7e2d40",
  },
  {
    name: "217-c",
    hex: "#eabedb",
  },
  {
    name: "218-c",
    hex: "#e56db1",
  },
  {
    name: "219-c",
    hex: "#da1884",
  },
  {
    name: "220-c",
    hex: "#a50050",
  },
  {
    name: "221-c",
    hex: "#910048",
  },
  {
    name: "222-c",
    hex: "#6c1d45",
  },
  {
    name: "7639-c",
    hex: "#936d73",
  },
  {
    name: "7640-c",
    hex: "#934054",
  },
  {
    name: "7641-c",
    hex: "#8e2c48",
  },
  {
    name: "7642-c",
    hex: "#732e4a",
  },
  {
    name: "7643-c",
    hex: "#672e45",
  },
  {
    name: "7644-c",
    hex: "#582d40",
  },
  {
    name: "7645-c",
    hex: "#502b3a",
  },
  {
    name: "223-c",
    hex: "#ef95cf",
  },
  {
    name: "224-c",
    hex: "#eb6fbd",
  },
  {
    name: "225-c",
    hex: "#df1995",
  },
  {
    name: "226-c",
    hex: "#d0006f",
  },
  {
    name: "227-c",
    hex: "#aa0061",
  },
  {
    name: "228-c",
    hex: "#890c58",
  },
  {
    name: "229-c",
    hex: "#672146",
  },
  {
    name: "230-c",
    hex: "#f4a6d7",
  },
  {
    name: "231-c",
    hex: "#f277c6",
  },
  {
    name: "232-c",
    hex: "#e93cac",
  },
  {
    name: "233-c",
    hex: "#c6007e",
  },
  {
    name: "234-c",
    hex: "#a20067",
  },
  {
    name: "235-c",
    hex: "#840b55",
  },
  {
    name: "670-c",
    hex: "#ead3e2",
  },
  {
    name: "671-c",
    hex: "#e6bcd8",
  },
  {
    name: "672-c",
    hex: "#dfa0c9",
  },
  {
    name: "673-c",
    hex: "#d986ba",
  },
  {
    name: "674-c",
    hex: "#c6579a",
  },
  {
    name: "675-c",
    hex: "#ae2573",
  },
  {
    name: "676-c",
    hex: "#960051",
  },
  {
    name: "677-c",
    hex: "#e5cedb",
  },
  {
    name: "678-c",
    hex: "#e3c8d8",
  },
  {
    name: "679-c",
    hex: "#debed2",
  },
  {
    name: "680-c",
    hex: "#c996b6",
  },
  {
    name: "681-c",
    hex: "#b06c96",
  },
  {
    name: "682-c",
    hex: "#994878",
  },
  {
    name: "683-c",
    hex: "#7c2855",
  },
  {
    name: "684-c",
    hex: "#e4c6d4",
  },
  {
    name: "685-c",
    hex: "#dcb6c9",
  },
  {
    name: "686-c",
    hex: "#d0a1ba",
  },
  {
    name: "687-c",
    hex: "#be84a3",
  },
  {
    name: "688-c",
    hex: "#a76389",
  },
  {
    name: "689-c",
    hex: "#893b67",
  },
  {
    name: "690-c",
    hex: "#612141",
  },
  {
    name: "510-c",
    hex: "#ebbecb",
  },
  {
    name: "509-c",
    hex: "#e8b3c3",
  },
  {
    name: "508-c",
    hex: "#e4a9bb",
  },
  {
    name: "507-c",
    hex: "#d592aa",
  },
  {
    name: "506-c",
    hex: "#84344e",
  },
  {
    name: "505-c",
    hex: "#6f2c3f",
  },
  {
    name: "504-c",
    hex: "#572932",
  },
  {
    name: "7429-c",
    hex: "#e2bccb",
  },
  {
    name: "7430-c",
    hex: "#dca9bf",
  },
  {
    name: "7431-c",
    hex: "#c9809e",
  },
  {
    name: "7432-c",
    hex: "#b55c80",
  },
  {
    name: "7433-c",
    hex: "#a73a64",
  },
  {
    name: "7434-c",
    hex: "#9b3259",
  },
  {
    name: "7435-c",
    hex: "#872651",
  },
  {
    name: "691-c",
    hex: "#e9cdd0",
  },
  {
    name: "692-c",
    hex: "#e4bec3",
  },
  {
    name: "693-c",
    hex: "#d7a3ab",
  },
  {
    name: "694-c",
    hex: "#c48490",
  },
  {
    name: "695-c",
    hex: "#b46b7a",
  },
  {
    name: "696-c",
    hex: "#984856",
  },
  {
    name: "697-c",
    hex: "#893c47",
  },
  {
    name: "496-c",
    hex: "#f2c6cf",
  },
  {
    name: "495-c",
    hex: "#f1bdc8",
  },
  {
    name: "494-c",
    hex: "#e9a2b2",
  },
  {
    name: "493-c",
    hex: "#dc8699",
  },
  {
    name: "492-c",
    hex: "#8f3237",
  },
  {
    name: "491-c",
    hex: "#7f3035",
  },
  {
    name: "490-c",
    hex: "#5d2a2c",
  },
  {
    name: "503-c",
    hex: "#e9c4c7",
  },
  {
    name: "502-c",
    hex: "#e5bac1",
  },
  {
    name: "501-c",
    hex: "#daa5ad",
  },
  {
    name: "500-c",
    hex: "#c6858f",
  },
  {
    name: "499-c",
    hex: "#7a3e3a",
  },
  {
    name: "498-c",
    hex: "#6a3735",
  },
  {
    name: "497-c",
    hex: "#512f2e",
  },
  {
    name: "5035-c",
    hex: "#dfc2c3",
  },
  {
    name: "5025-c",
    hex: "#dbb7bb",
  },
  {
    name: "5015-c",
    hex: "#cca1a6",
  },
  {
    name: "5005-c",
    hex: "#b07c83",
  },
  {
    name: "4995-c",
    hex: "#9c6169",
  },
  {
    name: "4985-c",
    hex: "#874b52",
  },
  {
    name: "4975-c",
    hex: "#3f2021",
  },
  {
    name: "236-c",
    hex: "#f1a7dc",
  },
  {
    name: "237-c",
    hex: "#ec86d0",
  },
  {
    name: "238-c",
    hex: "#e45dbf",
  },
  {
    name: "239-c",
    hex: "#db3eb1",
  },
  {
    name: "240-c",
    hex: "#c5299b",
  },
  {
    name: "241-c",
    hex: "#af1685",
  },
  {
    name: "242-c",
    hex: "#80225f",
  },
  {
    name: "2365-c",
    hex: "#efbae1",
  },
  {
    name: "2375-c",
    hex: "#e277cd",
  },
  {
    name: "2385-c",
    hex: "#d539b5",
  },
  {
    name: "2395-c",
    hex: "#c800a1",
  },
  {
    name: "2405-c",
    hex: "#b0008e",
  },
  {
    name: "2415-c",
    hex: "#9e007e",
  },
  {
    name: "2425-c",
    hex: "#830065",
  },
  {
    name: "243-c",
    hex: "#eab8e4",
  },
  {
    name: "244-c",
    hex: "#e59bdc",
  },
  {
    name: "245-c",
    hex: "#dd7fd3",
  },
  {
    name: "246-c",
    hex: "#c724b1",
  },
  {
    name: "247-c",
    hex: "#bb16a3",
  },
  {
    name: "248-c",
    hex: "#a51890",
  },
  {
    name: "249-c",
    hex: "#80276c",
  },
  {
    name: "7646-c",
    hex: "#a56e87",
  },
  {
    name: "7647-c",
    hex: "#a83d72",
  },
  {
    name: "7648-c",
    hex: "#991e66",
  },
  {
    name: "7649-c",
    hex: "#8a1b61",
  },
  {
    name: "7650-c",
    hex: "#722257",
  },
  {
    name: "7651-c",
    hex: "#6a2a5b",
  },
  {
    name: "7652-c",
    hex: "#5e2751",
  },
  {
    name: "250-c",
    hex: "#e7bae4",
  },
  {
    name: "251-c",
    hex: "#dd9cdf",
  },
  {
    name: "252-c",
    hex: "#c964cf",
  },
  {
    name: "253-c",
    hex: "#ad1aac",
  },
  {
    name: "254-c",
    hex: "#981d97",
  },
  {
    name: "255-c",
    hex: "#72246c",
  },
  {
    name: "517-c",
    hex: "#ebc6df",
  },
  {
    name: "516-c",
    hex: "#e6bedd",
  },
  {
    name: "515-c",
    hex: "#e2acd7",
  },
  {
    name: "514-c",
    hex: "#d48bc8",
  },
  {
    name: "513-c",
    hex: "#93328e",
  },
  {
    name: "512-c",
    hex: "#833177",
  },
  {
    name: "511-c",
    hex: "#612c51",
  },
  {
    name: "7436-c",
    hex: "#eedaea",
  },
  {
    name: "7437-c",
    hex: "#ccaed0",
  },
  {
    name: "7438-c",
    hex: "#d59ed7",
  },
  {
    name: "7439-c",
    hex: "#b288b9",
  },
  {
    name: "7440-c",
    hex: "#a277a6",
  },
  {
    name: "7441-c",
    hex: "#9f5cc0",
  },
  {
    name: "7442-c",
    hex: "#963cbd",
  },
  {
    name: "2562-c",
    hex: "#d7a9e3",
  },
  {
    name: "2572-c",
    hex: "#c98bdb",
  },
  {
    name: "2582-c",
    hex: "#ac4fc6",
  },
  {
    name: "2592-c",
    hex: "#9b26b6",
  },
  {
    name: "2602-c",
    hex: "#87189d",
  },
  {
    name: "2612-c",
    hex: "#772583",
  },
  {
    name: "2622-c",
    hex: "#653165",
  },
  {
    name: "7653-c",
    hex: "#948794",
  },
  {
    name: "7654-c",
    hex: "#a2789c",
  },
  {
    name: "7655-c",
    hex: "#a15a95",
  },
  {
    name: "7656-c",
    hex: "#8e3a80",
  },
  {
    name: "7657-c",
    hex: "#6e2b62",
  },
  {
    name: "7658-c",
    hex: "#6a3460",
  },
  {
    name: "7659-c",
    hex: "#5d3754",
  },
  {
    name: "524-c",
    hex: "#d5c2d8",
  },
  {
    name: "523-c",
    hex: "#c9b1d0",
  },
  {
    name: "522-c",
    hex: "#ba9cc5",
  },
  {
    name: "521-c",
    hex: "#a57fb2",
  },
  {
    name: "520-c",
    hex: "#642f6c",
  },
  {
    name: "519-c",
    hex: "#59315f",
  },
  {
    name: "518-c",
    hex: "#4b3048",
  },
  {
    name: "5245-c",
    hex: "#dbcdd3",
  },
  {
    name: "5235-c",
    hex: "#d0bec7",
  },
  {
    name: "5225-c",
    hex: "#c6b0bc",
  },
  {
    name: "5215-c",
    hex: "#af95a6",
  },
  {
    name: "5205-c",
    hex: "#86647a",
  },
  {
    name: "5195-c",
    hex: "#66435a",
  },
  {
    name: "5185-c",
    hex: "#4a3041",
  },
  {
    name: "5175-c",
    hex: "#d8c8d1",
  },
  {
    name: "5165-c",
    hex: "#d3c0cd",
  },
  {
    name: "5155-c",
    hex: "#bfa5b8",
  },
  {
    name: "5145-c",
    hex: "#9b7793",
  },
  {
    name: "5135-c",
    hex: "#7e5475",
  },
  {
    name: "5125-c",
    hex: "#693c5e",
  },
  {
    name: "5115-c",
    hex: "#512a44",
  },
  {
    name: "531-c",
    hex: "#dfc8e7",
  },
  {
    name: "530-c",
    hex: "#d7b9e4",
  },
  {
    name: "529-c",
    hex: "#caa2dd",
  },
  {
    name: "528-c",
    hex: "#b580d1",
  },
  {
    name: "527-c",
    hex: "#8031a7",
  },
  {
    name: "526-c",
    hex: "#702f8a",
  },
  {
    name: "525-c",
    hex: "#572c5f",
  },
  {
    name: "256-c",
    hex: "#d6bfdd",
  },
  {
    name: "257-c",
    hex: "#c6a1cf",
  },
  {
    name: "258-c",
    hex: "#8c4799",
  },
  {
    name: "259-c",
    hex: "#6d2077",
  },
  {
    name: "260-c",
    hex: "#642667",
  },
  {
    name: "261-c",
    hex: "#5d285f",
  },
  {
    name: "262-c",
    hex: "#51284f",
  },
  {
    name: "2563-c",
    hex: "#cba3d8",
  },
  {
    name: "2573-c",
    hex: "#b884cb",
  },
  {
    name: "2583-c",
    hex: "#a05eb5",
  },
  {
    name: "2593-c",
    hex: "#84329b",
  },
  {
    name: "2603-c",
    hex: "#702082",
  },
  {
    name: "2613-c",
    hex: "#671e75",
  },
  {
    name: "2623-c",
    hex: "#5f2167",
  },
  {
    name: "7660-c",
    hex: "#9991a4",
  },
  {
    name: "7661-c",
    hex: "#8d6e97",
  },
  {
    name: "7662-c",
    hex: "#7a4183",
  },
  {
    name: "7663-c",
    hex: "#6b3077",
  },
  {
    name: "7664-c",
    hex: "#653279",
  },
  {
    name: "7665-c",
    hex: "#5e366e",
  },
  {
    name: "7666-c",
    hex: "#5c4e63",
  },
  {
    name: "2567-c",
    hex: "#c1a0da",
  },
  {
    name: "2577-c",
    hex: "#a77bca",
  },
  {
    name: "2587-c",
    hex: "#8246af",
  },
  {
    name: "2597-c",
    hex: "#5c068c",
  },
  {
    name: "2607-c",
    hex: "#500778",
  },
  {
    name: "2617-c",
    hex: "#470a68",
  },
  {
    name: "2627-c",
    hex: "#3c1053",
  },
  {
    name: "263-c",
    hex: "#d7c6e6",
  },
  {
    name: "264-c",
    hex: "#c1a7e2",
  },
  {
    name: "265-c",
    hex: "#9063cd",
  },
  {
    name: "266-c",
    hex: "#753bbd",
  },
  {
    name: "267-c",
    hex: "#5f259f",
  },
  {
    name: "268-c",
    hex: "#582c83",
  },
  {
    name: "269-c",
    hex: "#512d6d",
  },
  {
    name: "2635-c",
    hex: "#c5b4e3",
  },
  {
    name: "2645-c",
    hex: "#ad96dc",
  },
  {
    name: "2655-c",
    hex: "#9678d3",
  },
  {
    name: "2665-c",
    hex: "#7d55c7",
  },
  {
    name: "2685-c",
    hex: "#330072",
  },
  {
    name: "2695-c",
    hex: "#2e1a47",
  },
  {
    name: "270-c",
    hex: "#b4b5df",
  },
  {
    name: "271-c",
    hex: "#9595d2",
  },
  {
    name: "272-c",
    hex: "#7474c1",
  },
  {
    name: "273-c",
    hex: "#24135f",
  },
  {
    name: "274-c",
    hex: "#211551",
  },
  {
    name: "275-c",
    hex: "#201747",
  },
  {
    name: "276-c",
    hex: "#221c35",
  },
  {
    name: "2705-c",
    hex: "#a7a4e0",
  },
  {
    name: "2715-c",
    hex: "#8b84d7",
  },
  {
    name: "2725-c",
    hex: "#685bc7",
  },
  {
    name: "2735-c",
    hex: "#2e008b",
  },
  {
    name: "2745-c",
    hex: "#280071",
  },
  {
    name: "2755-c",
    hex: "#250e62",
  },
  {
    name: "2765-c",
    hex: "#201547",
  },
  {
    name: "7667-c",
    hex: "#6e7ca0",
  },
  {
    name: "7668-c",
    hex: "#686e9f",
  },
  {
    name: "7669-c",
    hex: "#615e9b",
  },
  {
    name: "7670-c",
    hex: "#565294",
  },
  {
    name: "7671-c",
    hex: "#514689",
  },
  {
    name: "7672-c",
    hex: "#4c4184",
  },
  {
    name: "7673-c",
    hex: "#535486",
  },
  {
    name: "7443-c",
    hex: "#dddae8",
  },
  {
    name: "7444-c",
    hex: "#b6b8dc",
  },
  {
    name: "7445-c",
    hex: "#a7a2c3",
  },
  {
    name: "7446-c",
    hex: "#8986ca",
  },
  {
    name: "7447-c",
    hex: "#5d4777",
  },
  {
    name: "7448-c",
    hex: "#4b384c",
  },
  {
    name: "7449-c",
    hex: "#41273b",
  },
  {
    name: "7674-c",
    hex: "#878cb4",
  },
  {
    name: "7675-c",
    hex: "#7c7fab",
  },
  {
    name: "7676-c",
    hex: "#7566a0",
  },
  {
    name: "7677-c",
    hex: "#6f5091",
  },
  {
    name: "7678-c",
    hex: "#68478d",
  },
  {
    name: "7679-c",
    hex: "#563d82",
  },
  {
    name: "7680-c",
    hex: "#523178",
  },
  {
    name: "663-c",
    hex: "#e5e1e6",
  },
  {
    name: "664-c",
    hex: "#e0dbe3",
  },
  {
    name: "665-c",
    hex: "#c6bcd0",
  },
  {
    name: "666-c",
    hex: "#a192b2",
  },
  {
    name: "667-c",
    hex: "#7c6992",
  },
  {
    name: "668-c",
    hex: "#614b79",
  },
  {
    name: "669-c",
    hex: "#3f2a56",
  },
  {
    name: "5315-c",
    hex: "#d8d7df",
  },
  {
    name: "5305-c",
    hex: "#c6c4d2",
  },
  {
    name: "5295-c",
    hex: "#b3b0c4",
  },
  {
    name: "5285-c",
    hex: "#8d89a5",
  },
  {
    name: "5275-c",
    hex: "#595478",
  },
  {
    name: "5265-c",
    hex: "#403a60",
  },
  {
    name: "5255-c",
    hex: "#1e1a34",
  },
  {
    name: "538-c",
    hex: "#c5cfda",
  },
  {
    name: "537-c",
    hex: "#bbc7d6",
  },
  {
    name: "536-c",
    hex: "#a2b2c8",
  },
  {
    name: "535-c",
    hex: "#8e9fbc",
  },
  {
    name: "534-c",
    hex: "#1b365d",
  },
  {
    name: "533-c",
    hex: "#1f2a44",
  },
  {
    name: "532-c",
    hex: "#1c1f2a",
  },
  {
    name: "7541-c",
    hex: "#d9e1e2",
  },
  {
    name: "7542-c",
    hex: "#a4bcc2",
  },
  {
    name: "7543-c",
    hex: "#98a4ae",
  },
  {
    name: "7544-c",
    hex: "#768692",
  },
  {
    name: "7545-c",
    hex: "#425563",
  },
  {
    name: "7546-c",
    hex: "#253746",
  },
  {
    name: "7547-c",
    hex: "#131e29",
  },
  {
    name: "552-c",
    hex: "#b9d3dc",
  },
  {
    name: "551-c",
    hex: "#a3c7d2",
  },
  {
    name: "550-c",
    hex: "#8db9ca",
  },
  {
    name: "549-c",
    hex: "#6ba4b8",
  },
  {
    name: "548-c",
    hex: "#003d4c",
  },
  {
    name: "547-c",
    hex: "#00313c",
  },
  {
    name: "546-c",
    hex: "#072b31",
  },
  {
    name: "5455-c",
    hex: "#bfced6",
  },
  {
    name: "5445-c",
    hex: "#b7c9d3",
  },
  {
    name: "5435-c",
    hex: "#a6bbc8",
  },
  {
    name: "5425-c",
    hex: "#7a99ac",
  },
  {
    name: "5415-c",
    hex: "#5b7f95",
  },
  {
    name: "5405-c",
    hex: "#4f758b",
  },
  {
    name: "5395-c",
    hex: "#081f2c",
  },
  {
    name: "642-c",
    hex: "#d1dde6",
  },
  {
    name: "643-c",
    hex: "#c6d6e3",
  },
  {
    name: "644-c",
    hex: "#9bb8d3",
  },
  {
    name: "645-c",
    hex: "#7da1c4",
  },
  {
    name: "646-c",
    hex: "#5e8ab4",
  },
  {
    name: "647-c",
    hex: "#236192",
  },
  {
    name: "648-c",
    hex: "#002e5d",
  },
  {
    name: "649-c",
    hex: "#dbe2e9",
  },
  {
    name: "650-c",
    hex: "#ced9e5",
  },
  {
    name: "651-c",
    hex: "#a7bcd6",
  },
  {
    name: "652-c",
    hex: "#7d9bc1",
  },
  {
    name: "653-c",
    hex: "#326295",
  },
  {
    name: "654-c",
    hex: "#003a70",
  },
  {
    name: "655-c",
    hex: "#002554",
  },
  {
    name: "656-c",
    hex: "#dde5ed",
  },
  {
    name: "657-c",
    hex: "#c8d8eb",
  },
  {
    name: "658-c",
    hex: "#b1c9e8",
  },
  {
    name: "659-c",
    hex: "#7ba4db",
  },
  {
    name: "660-c",
    hex: "#407ec9",
  },
  {
    name: "661-c",
    hex: "#003594",
  },
  {
    name: "662-c",
    hex: "#001a70",
  },
  {
    name: "7450-c",
    hex: "#bdc5db",
  },
  {
    name: "7451-c",
    hex: "#89abe3",
  },
  {
    name: "7452-c",
    hex: "#8094dd",
  },
  {
    name: "7453-c",
    hex: "#7ba6de",
  },
  {
    name: "7454-c",
    hex: "#5f8fb4",
  },
  {
    name: "7455-c",
    hex: "#3a5dae",
  },
  {
    name: "7456-c",
    hex: "#606eb2",
  },
  {
    name: "2706-c",
    hex: "#cbd3eb",
  },
  {
    name: "2716-c",
    hex: "#9faee5",
  },
  {
    name: "2726-c",
    hex: "#485cc7",
  },
  {
    name: "2736-c",
    hex: "#1e22aa",
  },
  {
    name: "2746-c",
    hex: "#171c8f",
  },
  {
    name: "2756-c",
    hex: "#151f6d",
  },
  {
    name: "2766-c",
    hex: "#141b4d",
  },
  {
    name: "2708-c",
    hex: "#b8ccea",
  },
  {
    name: "2718-c",
    hex: "#5c88da",
  },
  {
    name: "2728-c",
    hex: "#0047bb",
  },
  {
    name: "2738-c",
    hex: "#06038d",
  },
  {
    name: "2748-c",
    hex: "#001871",
  },
  {
    name: "2758-c",
    hex: "#001e62",
  },
  {
    name: "2768-c",
    hex: "#071d49",
  },
  {
    name: "2707-c",
    hex: "#c3d7ee",
  },
  {
    name: "2717-c",
    hex: "#a7c6ed",
  },
  {
    name: "2727-c",
    hex: "#307fe2",
  },
  {
    name: "2747-c",
    hex: "#001a72",
  },
  {
    name: "2757-c",
    hex: "#001e60",
  },
  {
    name: "2767-c",
    hex: "#13294b",
  },
  {
    name: "277-c",
    hex: "#abcae9",
  },
  {
    name: "278-c",
    hex: "#8bb8e8",
  },
  {
    name: "279-c",
    hex: "#418fde",
  },
  {
    name: "280-c",
    hex: "#012169",
  },
  {
    name: "281-c",
    hex: "#00205b",
  },
  {
    name: "282-c",
    hex: "#041e42",
  },
  {
    name: "283-c",
    hex: "#92c1e9",
  },
  {
    name: "284-c",
    hex: "#6cace4",
  },
  {
    name: "285-c",
    hex: "#0072ce",
  },
  {
    name: "286-c",
    hex: "#0033a0",
  },
  {
    name: "287-c",
    hex: "#003087",
  },
  {
    name: "288-c",
    hex: "#002d72",
  },
  {
    name: "289-c",
    hex: "#0c2340",
  },
  {
    name: "7681-c",
    hex: "#94a9cb",
  },
  {
    name: "7682-c",
    hex: "#6787b7",
  },
  {
    name: "7683-c",
    hex: "#426da9",
  },
  {
    name: "7684-c",
    hex: "#385e9d",
  },
  {
    name: "7685-c",
    hex: "#2c5697",
  },
  {
    name: "7686-c",
    hex: "#1d4f91",
  },
  {
    name: "7687-c",
    hex: "#1d428a",
  },
  {
    name: "545-c",
    hex: "#c6dae7",
  },
  {
    name: "544-c",
    hex: "#bdd6e6",
  },
  {
    name: "543-c",
    hex: "#a4c8e1",
  },
  {
    name: "542-c",
    hex: "#7bafd4",
  },
  {
    name: "541-c",
    hex: "#003c71",
  },
  {
    name: "540-c",
    hex: "#003057",
  },
  {
    name: "539-c",
    hex: "#00263a",
  },
  {
    name: "290-c",
    hex: "#b9d9eb",
  },
  {
    name: "291-c",
    hex: "#9bcbeb",
  },
  {
    name: "292-c",
    hex: "#69b3e7",
  },
  {
    name: "293-c",
    hex: "#003da5",
  },
  {
    name: "294-c",
    hex: "#002f6c",
  },
  {
    name: "295-c",
    hex: "#002855",
  },
  {
    name: "296-c",
    hex: "#041c2c",
  },
  {
    name: "2905-c",
    hex: "#8dc8e8",
  },
  {
    name: "2915-c",
    hex: "#62b5e5",
  },
  {
    name: "2925-c",
    hex: "#009cde",
  },
  {
    name: "2935-c",
    hex: "#0057b8",
  },
  {
    name: "2945-c",
    hex: "#004c97",
  },
  {
    name: "2955-c",
    hex: "#003865",
  },
  {
    name: "2965-c",
    hex: "#00263e",
  },
  {
    name: "297-c",
    hex: "#71c5e8",
  },
  {
    name: "298-c",
    hex: "#41b6e6",
  },
  {
    name: "299-c",
    hex: "#00a3e0",
  },
  {
    name: "300-c",
    hex: "#005eb8",
  },
  {
    name: "301-c",
    hex: "#004b87",
  },
  {
    name: "302-c",
    hex: "#003b5c",
  },
  {
    name: "303-c",
    hex: "#002a3a",
  },
  {
    name: "7688-c",
    hex: "#4698cb",
  },
  {
    name: "7689-c",
    hex: "#298fc2",
  },
  {
    name: "7690-c",
    hex: "#0076a8",
  },
  {
    name: "7691-c",
    hex: "#006298",
  },
  {
    name: "7692-c",
    hex: "#005587",
  },
  {
    name: "7693-c",
    hex: "#004976",
  },
  {
    name: "7694-c",
    hex: "#01426a",
  },
  {
    name: "2975-c",
    hex: "#99d6ea",
  },
  {
    name: "2985-c",
    hex: "#5bc2e7",
  },
  {
    name: "2995-c",
    hex: "#00a9e0",
  },
  {
    name: "3005-c",
    hex: "#0077c8",
  },
  {
    name: "3015-c",
    hex: "#00629b",
  },
  {
    name: "3025-c",
    hex: "#004f71",
  },
  {
    name: "3035-c",
    hex: "#003e51",
  },
  {
    name: "7695-c",
    hex: "#7ba7bc",
  },
  {
    name: "7696-c",
    hex: "#6399ae",
  },
  {
    name: "7697-c",
    hex: "#4e87a0",
  },
  {
    name: "7698-c",
    hex: "#41748d",
  },
  {
    name: "7699-c",
    hex: "#34657f",
  },
  {
    name: "7700-c",
    hex: "#165c7d",
  },
  {
    name: "7701-c",
    hex: "#005776",
  },
  {
    name: "7457-c",
    hex: "#bbdde6",
  },
  {
    name: "7458-c",
    hex: "#71b2c9",
  },
  {
    name: "7459-c",
    hex: "#4298b5",
  },
  {
    name: "7460-c",
    hex: "#0086bf",
  },
  {
    name: "7461-c",
    hex: "#007dba",
  },
  {
    name: "7462-c",
    hex: "#00558c",
  },
  {
    name: "7463-c",
    hex: "#002b49",
  },
  {
    name: "304-c",
    hex: "#9adbe8",
  },
  {
    name: "305-c",
    hex: "#59cbe8",
  },
  {
    name: "306-c",
    hex: "#00b5e2",
  },
  {
    name: "307-c",
    hex: "#006ba6",
  },
  {
    name: "308-c",
    hex: "#00587c",
  },
  {
    name: "309-c",
    hex: "#003b49",
  },
  {
    name: "635-c",
    hex: "#a4dbe8",
  },
  {
    name: "636-c",
    hex: "#8bd3e6",
  },
  {
    name: "637-c",
    hex: "#4ec3e0",
  },
  {
    name: "638-c",
    hex: "#00afd7",
  },
  {
    name: "639-c",
    hex: "#0095c8",
  },
  {
    name: "640-c",
    hex: "#0082ba",
  },
  {
    name: "641-c",
    hex: "#0067a0",
  },
  {
    name: "7702-c",
    hex: "#48a9c5",
  },
  {
    name: "7703-c",
    hex: "#009cbd",
  },
  {
    name: "7704-c",
    hex: "#0085ad",
  },
  {
    name: "7705-c",
    hex: "#007096",
  },
  {
    name: "7706-c",
    hex: "#006a8e",
  },
  {
    name: "7707-c",
    hex: "#00617f",
  },
  {
    name: "7708-c",
    hex: "#005670",
  },
  {
    name: "628-c",
    hex: "#b8dde1",
  },
  {
    name: "629-c",
    hex: "#9bd3dd",
  },
  {
    name: "630-c",
    hex: "#77c5d5",
  },
  {
    name: "631-c",
    hex: "#3eb1c8",
  },
  {
    name: "632-c",
    hex: "#0093b2",
  },
  {
    name: "633-c",
    hex: "#007396",
  },
  {
    name: "634-c",
    hex: "#005f83",
  },
  {
    name: "310-c",
    hex: "#6ad1e3",
  },
  {
    name: "311-c",
    hex: "#05c3de",
  },
  {
    name: "312-c",
    hex: "#00a9ce",
  },
  {
    name: "313-c",
    hex: "#0092bc",
  },
  {
    name: "314-c",
    hex: "#007fa3",
  },
  {
    name: "315-c",
    hex: "#00677f",
  },
  {
    name: "316-c",
    hex: "#004851",
  },
  {
    name: "3105-c",
    hex: "#68d2df",
  },
  {
    name: "3115-c",
    hex: "#00c1d5",
  },
  {
    name: "3125-c",
    hex: "#00aec7",
  },
  {
    name: "3135-c",
    hex: "#008eaa",
  },
  {
    name: "3145-c",
    hex: "#00778b",
  },
  {
    name: "3155-c",
    hex: "#006272",
  },
  {
    name: "3165-c",
    hex: "#004f59",
  },
  {
    name: "7709-c",
    hex: "#63b1bc",
  },
  {
    name: "7710-c",
    hex: "#00a7b5",
  },
  {
    name: "7711-c",
    hex: "#0097a9",
  },
  {
    name: "7712-c",
    hex: "#00859b",
  },
  {
    name: "7713-c",
    hex: "#007d8a",
  },
  {
    name: "7714-c",
    hex: "#007680",
  },
  {
    name: "7715-c",
    hex: "#006269",
  },
  {
    name: "317-c",
    hex: "#b1e4e3",
  },
  {
    name: "318-c",
    hex: "#88dbdf",
  },
  {
    name: "319-c",
    hex: "#2dccd3",
  },
  {
    name: "320-c",
    hex: "#009ca6",
  },
  {
    name: "321-c",
    hex: "#008c95",
  },
  {
    name: "322-c",
    hex: "#007377",
  },
  {
    name: "323-c",
    hex: "#005f61",
  },
  {
    name: "7464-c",
    hex: "#a0d1ca",
  },
  {
    name: "7465-c",
    hex: "#40c1ac",
  },
  {
    name: "7466-c",
    hex: "#00b0b9",
  },
  {
    name: "7467-c",
    hex: "#00a3ad",
  },
  {
    name: "7468-c",
    hex: "#007398",
  },
  {
    name: "7469-c",
    hex: "#005f86",
  },
  {
    name: "7470-c",
    hex: "#005a70",
  },
  {
    name: "7471-c",
    hex: "#7eddd3",
  },
  {
    name: "7472-c",
    hex: "#5cb8b2",
  },
  {
    name: "7473-c",
    hex: "#279989",
  },
  {
    name: "7474-c",
    hex: "#007681",
  },
  {
    name: "7475-c",
    hex: "#487a7b",
  },
  {
    name: "7476-c",
    hex: "#0d5257",
  },
  {
    name: "7477-c",
    hex: "#244c5a",
  },
  {
    name: "5523-c",
    hex: "#b6cfd0",
  },
  {
    name: "5513-c",
    hex: "#abc7ca",
  },
  {
    name: "5503-c",
    hex: "#94b7bb",
  },
  {
    name: "5493-c",
    hex: "#7fa9ae",
  },
  {
    name: "5483-c",
    hex: "#4f868e",
  },
  {
    name: "5473-c",
    hex: "#115e67",
  },
  {
    name: "5463-c",
    hex: "#07272d",
  },
  {
    name: "7716-c",
    hex: "#00968f",
  },
  {
    name: "7717-c",
    hex: "#00857d",
  },
  {
    name: "7718-c",
    hex: "#007672",
  },
  {
    name: "7719-c",
    hex: "#006d68",
  },
  {
    name: "7720-c",
    hex: "#00635b",
  },
  {
    name: "7721-c",
    hex: "#005e5d",
  },
  {
    name: "7722-c",
    hex: "#005151",
  },
  {
    name: "324-c",
    hex: "#9cdbd9",
  },
  {
    name: "325-c",
    hex: "#64ccc9",
  },
  {
    name: "326-c",
    hex: "#00b2a9",
  },
  {
    name: "327-c",
    hex: "#008675",
  },
  {
    name: "328-c",
    hex: "#007367",
  },
  {
    name: "329-c",
    hex: "#00685e",
  },
  {
    name: "330-c",
    hex: "#00534c",
  },
  {
    name: "3242-c",
    hex: "#71dbd4",
  },
  {
    name: "3252-c",
    hex: "#2ad2c9",
  },
  {
    name: "3262-c",
    hex: "#00bfb3",
  },
  {
    name: "3272-c",
    hex: "#00a499",
  },
  {
    name: "3282-c",
    hex: "#008578",
  },
  {
    name: "3292-c",
    hex: "#00594f",
  },
  {
    name: "3302-c",
    hex: "#004c45",
  },
  {
    name: "3245-c",
    hex: "#7ce0d3",
  },
  {
    name: "3255-c",
    hex: "#2cd5c4",
  },
  {
    name: "3265-c",
    hex: "#00c7b1",
  },
  {
    name: "3275-c",
    hex: "#00b398",
  },
  {
    name: "3285-c",
    hex: "#009681",
  },
  {
    name: "3295-c",
    hex: "#007864",
  },
  {
    name: "3305-c",
    hex: "#004e42",
  },
  {
    name: "3248-c",
    hex: "#6dcdb8",
  },
  {
    name: "3258-c",
    hex: "#49c5b1",
  },
  {
    name: "3268-c",
    hex: "#00ab8e",
  },
  {
    name: "3278-c",
    hex: "#009b77",
  },
  {
    name: "3288-c",
    hex: "#008264",
  },
  {
    name: "3298-c",
    hex: "#006a52",
  },
  {
    name: "3308-c",
    hex: "#034638",
  },
  {
    name: "566-c",
    hex: "#b9dcd2",
  },
  {
    name: "565-c",
    hex: "#a1d6ca",
  },
  {
    name: "564-c",
    hex: "#86c8bc",
  },
  {
    name: "563-c",
    hex: "#6bbbae",
  },
  {
    name: "562-c",
    hex: "#006f62",
  },
  {
    name: "561-c",
    hex: "#00594c",
  },
  {
    name: "560-c",
    hex: "#1d3c34",
  },
  {
    name: "573-c",
    hex: "#b5e3d8",
  },
  {
    name: "572-c",
    hex: "#a5dfd3",
  },
  {
    name: "571-c",
    hex: "#98dbce",
  },
  {
    name: "570-c",
    hex: "#6bcaba",
  },
  {
    name: "569-c",
    hex: "#00816d",
  },
  {
    name: "568-c",
    hex: "#006c5b",
  },
  {
    name: "567-c",
    hex: "#173f35",
  },
  {
    name: "559-c",
    hex: "#adcab8",
  },
  {
    name: "558-c",
    hex: "#9abeaa",
  },
  {
    name: "557-c",
    hex: "#85b09a",
  },
  {
    name: "556-c",
    hex: "#6fa287",
  },
  {
    name: "555-c",
    hex: "#28724f",
  },
  {
    name: "554-c",
    hex: "#205c40",
  },
  {
    name: "553-c",
    hex: "#284734",
  },
  {
    name: "5595-c",
    hex: "#bfcec2",
  },
  {
    name: "5585-c",
    hex: "#a7bdb1",
  },
  {
    name: "5575-c",
    hex: "#92aca0",
  },
  {
    name: "5565-c",
    hex: "#7f9c90",
  },
  {
    name: "5555-c",
    hex: "#5c7f71",
  },
  {
    name: "5545-c",
    hex: "#43695b",
  },
  {
    name: "5535-c",
    hex: "#183028",
  },
  {
    name: "5665-c",
    hex: "#bac5b9",
  },
  {
    name: "5655-c",
    hex: "#b0bdb0",
  },
  {
    name: "5645-c",
    hex: "#a3b2a4",
  },
  {
    name: "5635-c",
    hex: "#94a596",
  },
  {
    name: "5625-c",
    hex: "#708573",
  },
  {
    name: "5615-c",
    hex: "#5e7461",
  },
  {
    name: "5605-c",
    hex: "#22372b",
  },
  {
    name: "5527-c",
    hex: "#bcc9c5",
  },
  {
    name: "5517-c",
    hex: "#b1c0bc",
  },
  {
    name: "5507-c",
    hex: "#9db0ac",
  },
  {
    name: "5497-c",
    hex: "#829995",
  },
  {
    name: "5487-c",
    hex: "#5d7975",
  },
  {
    name: "5477-c",
    hex: "#3e5d58",
  },
  {
    name: "5467-c",
    hex: "#18332f",
  },
  {
    name: "621-c",
    hex: "#d1e0d7",
  },
  {
    name: "622-c",
    hex: "#b7cdc2",
  },
  {
    name: "623-c",
    hex: "#9ab9ad",
  },
  {
    name: "624-c",
    hex: "#789f90",
  },
  {
    name: "625-c",
    hex: "#507f70",
  },
  {
    name: "626-c",
    hex: "#285c4d",
  },
  {
    name: "627-c",
    hex: "#13322b",
  },
  {
    name: "331-c",
    hex: "#a7e6d7",
  },
  {
    name: "332-c",
    hex: "#8ce2d0",
  },
  {
    name: "333-c",
    hex: "#3cdbc0",
  },
  {
    name: "334-c",
    hex: "#009775",
  },
  {
    name: "335-c",
    hex: "#007b5f",
  },
  {
    name: "336-c",
    hex: "#00664f",
  },
  {
    name: "337-c",
    hex: "#8fd6bd",
  },
  {
    name: "338-c",
    hex: "#6eceb2",
  },
  {
    name: "339-c",
    hex: "#00b388",
  },
  {
    name: "340-c",
    hex: "#00965e",
  },
  {
    name: "341-c",
    hex: "#007a53",
  },
  {
    name: "342-c",
    hex: "#006747",
  },
  {
    name: "343-c",
    hex: "#115740",
  },
  {
    name: "7723-c",
    hex: "#50a684",
  },
  {
    name: "7724-c",
    hex: "#00966c",
  },
  {
    name: "7725-c",
    hex: "#008755",
  },
  {
    name: "7726-c",
    hex: "#007b4b",
  },
  {
    name: "7727-c",
    hex: "#006f44",
  },
  {
    name: "7728-c",
    hex: "#006845",
  },
  {
    name: "7729-c",
    hex: "#005844",
  },
  {
    name: "3375-c",
    hex: "#7ae1bf",
  },
  {
    name: "3385-c",
    hex: "#47d7ac",
  },
  {
    name: "3395-c",
    hex: "#00c389",
  },
  {
    name: "3405-c",
    hex: "#00af66",
  },
  {
    name: "3415-c",
    hex: "#007749",
  },
  {
    name: "3425-c",
    hex: "#006341",
  },
  {
    name: "3435-c",
    hex: "#154734",
  },
  {
    name: "344-c",
    hex: "#a0dab3",
  },
  {
    name: "345-c",
    hex: "#91d6ac",
  },
  {
    name: "346-c",
    hex: "#71cc98",
  },
  {
    name: "347-c",
    hex: "#009a44",
  },
  {
    name: "348-c",
    hex: "#00843d",
  },
  {
    name: "349-c",
    hex: "#046a38",
  },
  {
    name: "350-c",
    hex: "#2c5234",
  },
  {
    name: "351-c",
    hex: "#a2e4b8",
  },
  {
    name: "352-c",
    hex: "#8fe2b0",
  },
  {
    name: "353-c",
    hex: "#80e0a7",
  },
  {
    name: "354-c",
    hex: "#00b140",
  },
  {
    name: "355-c",
    hex: "#009639",
  },
  {
    name: "356-c",
    hex: "#007a33",
  },
  {
    name: "357-c",
    hex: "#215732",
  },
  {
    name: "7478-c",
    hex: "#9be3bf",
  },
  {
    name: "7479-c",
    hex: "#26d07c",
  },
  {
    name: "7480-c",
    hex: "#00bf6f",
  },
  {
    name: "7481-c",
    hex: "#00b74f",
  },
  {
    name: "7482-c",
    hex: "#009f4d",
  },
  {
    name: "7483-c",
    hex: "#275d38",
  },
  {
    name: "7484-c",
    hex: "#00573f",
  },
  {
    name: "7730-c",
    hex: "#4b9560",
  },
  {
    name: "7731-c",
    hex: "#228848",
  },
  {
    name: "7732-c",
    hex: "#007a3e",
  },
  {
    name: "7733-c",
    hex: "#007041",
  },
  {
    name: "7734-c",
    hex: "#286140",
  },
  {
    name: "7735-c",
    hex: "#36573b",
  },
  {
    name: "7736-c",
    hex: "#395542",
  },
  {
    name: "7737-c",
    hex: "#6ba539",
  },
  {
    name: "7738-c",
    hex: "#48a23f",
  },
  {
    name: "7739-c",
    hex: "#319b42",
  },
  {
    name: "7740-c",
    hex: "#3a913f",
  },
  {
    name: "7741-c",
    hex: "#44883e",
  },
  {
    name: "7742-c",
    hex: "#4a773c",
  },
  {
    name: "7743-c",
    hex: "#44693d",
  },
  {
    name: "358-c",
    hex: "#addc91",
  },
  {
    name: "359-c",
    hex: "#a1d884",
  },
  {
    name: "360-c",
    hex: "#6cc24a",
  },
  {
    name: "361-c",
    hex: "#43b02a",
  },
  {
    name: "362-c",
    hex: "#509e2f",
  },
  {
    name: "363-c",
    hex: "#4c8c2b",
  },
  {
    name: "364-c",
    hex: "#4a7729",
  },
  {
    name: "7485-c",
    hex: "#d0debb",
  },
  {
    name: "7486-c",
    hex: "#bce194",
  },
  {
    name: "7487-c",
    hex: "#8edd65",
  },
  {
    name: "7488-c",
    hex: "#78d64b",
  },
  {
    name: "7489-c",
    hex: "#74aa50",
  },
  {
    name: "7490-c",
    hex: "#719949",
  },
  {
    name: "7491-c",
    hex: "#79863c",
  },
  {
    name: "365-c",
    hex: "#c2e189",
  },
  {
    name: "366-c",
    hex: "#b7dd79",
  },
  {
    name: "367-c",
    hex: "#a4d65e",
  },
  {
    name: "368-c",
    hex: "#78be20",
  },
  {
    name: "369-c",
    hex: "#64a70b",
  },
  {
    name: "370-c",
    hex: "#658d1b",
  },
  {
    name: "371-c",
    hex: "#546223",
  },
  {
    name: "372-c",
    hex: "#d4eb8e",
  },
  {
    name: "373-c",
    hex: "#cdea80",
  },
  {
    name: "374-c",
    hex: "#c5e86c",
  },
  {
    name: "375-c",
    hex: "#97d700",
  },
  {
    name: "376-c",
    hex: "#84bd00",
  },
  {
    name: "377-c",
    hex: "#7a9a01",
  },
  {
    name: "378-c",
    hex: "#59621d",
  },
  {
    name: "580-c",
    hex: "#c4d6a4",
  },
  {
    name: "579-c",
    hex: "#bcd19b",
  },
  {
    name: "578-c",
    hex: "#b7ce95",
  },
  {
    name: "577-c",
    hex: "#a9c47f",
  },
  {
    name: "576-c",
    hex: "#789d4a",
  },
  {
    name: "575-c",
    hex: "#67823a",
  },
  {
    name: "574-c",
    hex: "#4e5b31",
  },
  {
    name: "5807-c",
    hex: "#d0d1ab",
  },
  {
    name: "5797-c",
    hex: "#c6c89b",
  },
  {
    name: "5787-c",
    hex: "#babd8b",
  },
  {
    name: "5777-c",
    hex: "#a2a569",
  },
  {
    name: "5767-c",
    hex: "#8a8d4a",
  },
  {
    name: "5757-c",
    hex: "#6d712e",
  },
  {
    name: "5747-c",
    hex: "#3d441e",
  },
  {
    name: "5875-c",
    hex: "#d2ce9e",
  },
  {
    name: "5865-c",
    hex: "#cbc793",
  },
  {
    name: "5855-c",
    hex: "#c0bb87",
  },
  {
    name: "5845-c",
    hex: "#afa96e",
  },
  {
    name: "5835-c",
    hex: "#a09958",
  },
  {
    name: "5825-c",
    hex: "#89813d",
  },
  {
    name: "5815-c",
    hex: "#555025",
  },
  {
    name: "5803-c",
    hex: "#c3c6a8",
  },
  {
    name: "5793-c",
    hex: "#b3b995",
  },
  {
    name: "5783-c",
    hex: "#a3aa83",
  },
  {
    name: "5773-c",
    hex: "#899064",
  },
  {
    name: "5763-c",
    hex: "#737b4c",
  },
  {
    name: "5753-c",
    hex: "#5e6738",
  },
  {
    name: "5743-c",
    hex: "#3e4827",
  },
  {
    name: "7492-c",
    hex: "#bfcc80",
  },
  {
    name: "7493-c",
    hex: "#bbc592",
  },
  {
    name: "7494-c",
    hex: "#9caf88",
  },
  {
    name: "7495-c",
    hex: "#8f993e",
  },
  {
    name: "7496-c",
    hex: "#76881d",
  },
  {
    name: "7497-c",
    hex: "#7a7256",
  },
  {
    name: "7498-c",
    hex: "#5b6236",
  },
  {
    name: "7744-c",
    hex: "#babc16",
  },
  {
    name: "7745-c",
    hex: "#abad23",
  },
  {
    name: "7746-c",
    hex: "#999b30",
  },
  {
    name: "7747-c",
    hex: "#888d30",
  },
  {
    name: "7748-c",
    hex: "#7c8034",
  },
  {
    name: "7749-c",
    hex: "#727337",
  },
  {
    name: "7750-c",
    hex: "#656635",
  },
  {
    name: "379-c",
    hex: "#e2e868",
  },
  {
    name: "380-c",
    hex: "#dbe442",
  },
  {
    name: "381-c",
    hex: "#cedc00",
  },
  {
    name: "382-c",
    hex: "#c4d600",
  },
  {
    name: "383-c",
    hex: "#a8ad00",
  },
  {
    name: "384-c",
    hex: "#949300",
  },
  {
    name: "385-c",
    hex: "#787121",
  },
  {
    name: "386-c",
    hex: "#e9ec6b",
  },
  {
    name: "387-c",
    hex: "#e3e935",
  },
  {
    name: "388-c",
    hex: "#e0e721",
  },
  {
    name: "389-c",
    hex: "#d0df00",
  },
  {
    name: "390-c",
    hex: "#b5bd00",
  },
  {
    name: "391-c",
    hex: "#9a9500",
  },
  {
    name: "392-c",
    hex: "#827a04",
  },
  {
    name: "587-c",
    hex: "#e3e48d",
  },
  {
    name: "586-c",
    hex: "#e0e27c",
  },
  {
    name: "585-c",
    hex: "#dbde70",
  },
  {
    name: "584-c",
    hex: "#d2d755",
  },
  {
    name: "583-c",
    hex: "#b7bf10",
  },
  {
    name: "582-c",
    hex: "#8e8c13",
  },
  {
    name: "581-c",
    hex: "#625d20",
  },
  {
    name: "393-c",
    hex: "#f0ec74",
  },
  {
    name: "394-c",
    hex: "#ede939",
  },
  {
    name: "395-c",
    hex: "#ece81a",
  },
  {
    name: "396-c",
    hex: "#e1e000",
  },
  {
    name: "397-c",
    hex: "#bfb800",
  },
  {
    name: "398-c",
    hex: "#ada400",
  },
  {
    name: "399-c",
    hex: "#a09200",
  },
  {
    name: "3935-c",
    hex: "#f3ea5d",
  },
  {
    name: "3945-c",
    hex: "#f3e500",
  },
  {
    name: "3955-c",
    hex: "#efdf00",
  },
  {
    name: "3965-c",
    hex: "#eedc00",
  },
  {
    name: "3975-c",
    hex: "#bba600",
  },
  {
    name: "3985-c",
    hex: "#9a8700",
  },
  {
    name: "3995-c",
    hex: "#685c20",
  },
  {
    name: "600-c",
    hex: "#f1eb9c",
  },
  {
    name: "601-c",
    hex: "#f0e991",
  },
  {
    name: "602-c",
    hex: "#f0e87b",
  },
  {
    name: "603-c",
    hex: "#ede04b",
  },
  {
    name: "604-c",
    hex: "#eada24",
  },
  {
    name: "605-c",
    hex: "#e1cd00",
  },
  {
    name: "606-c",
    hex: "#cfb500",
  },
  {
    name: "607-c",
    hex: "#ebe49a",
  },
  {
    name: "608-c",
    hex: "#e9e186",
  },
  {
    name: "609-c",
    hex: "#e6de77",
  },
  {
    name: "610-c",
    hex: "#e1d555",
  },
  {
    name: "611-c",
    hex: "#d7c826",
  },
  {
    name: "612-c",
    hex: "#c4b000",
  },
  {
    name: "613-c",
    hex: "#b39b00",
  },
  {
    name: "461-c",
    hex: "#e9df97",
  },
  {
    name: "460-c",
    hex: "#e4d77e",
  },
  {
    name: "459-c",
    hex: "#decd63",
  },
  {
    name: "458-c",
    hex: "#d9c756",
  },
  {
    name: "457-c",
    hex: "#b89d18",
  },
  {
    name: "456-c",
    hex: "#a28e2a",
  },
  {
    name: "455-c",
    hex: "#695b24",
  },
  {
    name: "614-c",
    hex: "#dcd59a",
  },
  {
    name: "615-c",
    hex: "#d6cf8d",
  },
  {
    name: "616-c",
    hex: "#d0c883",
  },
  {
    name: "617-c",
    hex: "#c0b561",
  },
  {
    name: "618-c",
    hex: "#ac9f3c",
  },
  {
    name: "619-c",
    hex: "#9f912a",
  },
  {
    name: "620-c",
    hex: "#8a7b19",
  },
  {
    name: "7751-c",
    hex: "#cab64b",
  },
  {
    name: "7752-c",
    hex: "#cfb023",
  },
  {
    name: "7753-c",
    hex: "#c1a01e",
  },
  {
    name: "7754-c",
    hex: "#a08629",
  },
  {
    name: "7755-c",
    hex: "#897630",
  },
  {
    name: "7756-c",
    hex: "#736635",
  },
  {
    name: "7757-c",
    hex: "#675e33",
  },
  {
    name: "7758-c",
    hex: "#d4c304",
  },
  {
    name: "7759-c",
    hex: "#c4b200",
  },
  {
    name: "7760-c",
    hex: "#91852c",
  },
  {
    name: "7761-c",
    hex: "#747136",
  },
  {
    name: "7762-c",
    hex: "#5d6439",
  },
  {
    name: "7763-c",
    hex: "#585c3b",
  },
  {
    name: "7764-c",
    hex: "#535435",
  },
  {
    name: "7765-c",
    hex: "#bbb323",
  },
  {
    name: "7766-c",
    hex: "#b4a91f",
  },
  {
    name: "7767-c",
    hex: "#aa9d2e",
  },
  {
    name: "7768-c",
    hex: "#8f7e35",
  },
  {
    name: "7769-c",
    hex: "#716135",
  },
  {
    name: "7770-c",
    hex: "#635939",
  },
  {
    name: "7771-c",
    hex: "#4e4934",
  },
  {
    name: "4545-c",
    hex: "#d5cb9f",
  },
  {
    name: "4535-c",
    hex: "#cfc493",
  },
  {
    name: "4525-c",
    hex: "#c5b783",
  },
  {
    name: "4515-c",
    hex: "#b3a369",
  },
  {
    name: "4505-c",
    hex: "#998542",
  },
  {
    name: "4495-c",
    hex: "#8c7732",
  },
  {
    name: "4485-c",
    hex: "#614f25",
  },
  {
    name: "454-c",
    hex: "#cac7a7",
  },
  {
    name: "453-c",
    hex: "#bfbb98",
  },
  {
    name: "452-c",
    hex: "#b0aa7e",
  },
  {
    name: "451-c",
    hex: "#9b945f",
  },
  {
    name: "450-c",
    hex: "#594a25",
  },
  {
    name: "449-c",
    hex: "#524727",
  },
  {
    name: "448-c",
    hex: "#4a412a",
  },
  {
    name: "7499-c",
    hex: "#f1e6b2",
  },
  {
    name: "7500-c",
    hex: "#dfd1a7",
  },
  {
    name: "7501-c",
    hex: "#d9c89e",
  },
  {
    name: "7502-c",
    hex: "#ceb888",
  },
  {
    name: "7503-c",
    hex: "#a89968",
  },
  {
    name: "7504-c",
    hex: "#94795d",
  },
  {
    name: "7505-c",
    hex: "#816040",
  },
  {
    name: "468-c",
    hex: "#ddcba4",
  },
  {
    name: "467-c",
    hex: "#d3bc8d",
  },
  {
    name: "466-c",
    hex: "#c6aa76",
  },
  {
    name: "465-c",
    hex: "#b9975b",
  },
  {
    name: "464-c",
    hex: "#8b5b29",
  },
  {
    name: "463-c",
    hex: "#744f28",
  },
  {
    name: "462-c",
    hex: "#5c462b",
  },
  {
    name: "7506-c",
    hex: "#efdbb2",
  },
  {
    name: "7507-c",
    hex: "#fcd299",
  },
  {
    name: "7508-c",
    hex: "#e1b87f",
  },
  {
    name: "7509-c",
    hex: "#d6a461",
  },
  {
    name: "7510-c",
    hex: "#c6893f",
  },
  {
    name: "7511-c",
    hex: "#b77729",
  },
  {
    name: "7512-c",
    hex: "#a6631b",
  },
  {
    name: "719-c",
    hex: "#edc8a3",
  },
  {
    name: "720-c",
    hex: "#e7b78a",
  },
  {
    name: "721-c",
    hex: "#dda46f",
  },
  {
    name: "722-c",
    hex: "#c88242",
  },
  {
    name: "723-c",
    hex: "#b36924",
  },
  {
    name: "724-c",
    hex: "#934d11",
  },
  {
    name: "725-c",
    hex: "#7d3f16",
  },
  {
    name: "475-c",
    hex: "#f3cfb3",
  },
  {
    name: "474-c",
    hex: "#f1c6a7",
  },
  {
    name: "473-c",
    hex: "#f0bf9b",
  },
  {
    name: "472-c",
    hex: "#e59e6d",
  },
  {
    name: "471-c",
    hex: "#b86125",
  },
  {
    name: "470-c",
    hex: "#a45a2a",
  },
  {
    name: "469-c",
    hex: "#693f23",
  },
  {
    name: "726-c",
    hex: "#e0c09f",
  },
  {
    name: "727-c",
    hex: "#d9b48f",
  },
  {
    name: "728-c",
    hex: "#cda077",
  },
  {
    name: "729-c",
    hex: "#b58150",
  },
  {
    name: "730-c",
    hex: "#9e652e",
  },
  {
    name: "731-c",
    hex: "#774212",
  },
  {
    name: "732-c",
    hex: "#623412",
  },
  {
    name: "4685-c",
    hex: "#e0c6ad",
  },
  {
    name: "4675-c",
    hex: "#dcbfa6",
  },
  {
    name: "4665-c",
    hex: "#cda788",
  },
  {
    name: "4655-c",
    hex: "#bf9474",
  },
  {
    name: "4645-c",
    hex: "#ad7c59",
  },
  {
    name: "4635-c",
    hex: "#946037",
  },
  {
    name: "4625-c",
    hex: "#4f2c1d",
  },
  {
    name: "7513-c",
    hex: "#e1b7a7",
  },
  {
    name: "7514-c",
    hex: "#d5a286",
  },
  {
    name: "7515-c",
    hex: "#c58b68",
  },
  {
    name: "7516-c",
    hex: "#99552b",
  },
  {
    name: "7517-c",
    hex: "#85431e",
  },
  {
    name: "7518-c",
    hex: "#6d4f47",
  },
  {
    name: "7519-c",
    hex: "#5e4b3c",
  },
  {
    name: "4755-c",
    hex: "#d7c4b7",
  },
  {
    name: "4745-c",
    hex: "#cdb5a7",
  },
  {
    name: "4735-c",
    hex: "#c0a392",
  },
  {
    name: "4725-c",
    hex: "#ae8a79",
  },
  {
    name: "4715-c",
    hex: "#956c58",
  },
  {
    name: "4705-c",
    hex: "#7c4d3a",
  },
  {
    name: "4695-c",
    hex: "#5b3427",
  },
  {
    name: "482-c",
    hex: "#dbc8b6",
  },
  {
    name: "481-c",
    hex: "#d3bba8",
  },
  {
    name: "480-c",
    hex: "#c6a992",
  },
  {
    name: "479-c",
    hex: "#aa8066",
  },
  {
    name: "478-c",
    hex: "#703f2a",
  },
  {
    name: "477-c",
    hex: "#623b2a",
  },
  {
    name: "476-c",
    hex: "#4e3629",
  },
  {
    name: "7527-c",
    hex: "#d6d2c4",
  },
  {
    name: "7528-c",
    hex: "#c5b9ac",
  },
  {
    name: "7529-c",
    hex: "#b7a99a",
  },
  {
    name: "7530-c",
    hex: "#a39382",
  },
  {
    name: "7531-c",
    hex: "#7a6855",
  },
  {
    name: "7532-c",
    hex: "#63513d",
  },
  {
    name: "7533-c",
    hex: "#473729",
  },
  {
    name: "7534-c",
    hex: "#d1ccbd",
  },
  {
    name: "7535-c",
    hex: "#b7b09c",
  },
  {
    name: "7536-c",
    hex: "#a69f88",
  },
  {
    name: "7537-c",
    hex: "#a7aca2",
  },
  {
    name: "7538-c",
    hex: "#949a90",
  },
  {
    name: "7539-c",
    hex: "#8e9089",
  },
  {
    name: "7540-c",
    hex: "#4b4f54",
  },
  {
    name: "427-c",
    hex: "#d0d3d4",
  },
  {
    name: "428-c",
    hex: "#c1c6c8",
  },
  {
    name: "429-c",
    hex: "#a2aaad",
  },
  {
    name: "430-c",
    hex: "#7c878e",
  },
  {
    name: "431-c",
    hex: "#5b6770",
  },
  {
    name: "432-c",
    hex: "#333f48",
  },
  {
    name: "433-c",
    hex: "#1d252d",
  },
  {
    name: "420-c",
    hex: "#c7c9c7",
  },
  {
    name: "421-c",
    hex: "#b2b4b2",
  },
  {
    name: "422-c",
    hex: "#9ea2a2",
  },
  {
    name: "423-c",
    hex: "#898d8d",
  },
  {
    name: "424-c",
    hex: "#707372",
  },
  {
    name: "425-c",
    hex: "#54585a",
  },
  {
    name: "426-c",
    hex: "#25282a",
  },
  {
    name: "441-c",
    hex: "#bec6c4",
  },
  {
    name: "442-c",
    hex: "#a2acab",
  },
  {
    name: "443-c",
    hex: "#919d9d",
  },
  {
    name: "444-c",
    hex: "#717c7d",
  },
  {
    name: "445-c",
    hex: "#505759",
  },
  {
    name: "446-c",
    hex: "#3f4444",
  },
  {
    name: "447-c",
    hex: "#373a36",
  },
  {
    name: "413-c",
    hex: "#babbb1",
  },
  {
    name: "414-c",
    hex: "#a8a99e",
  },
  {
    name: "415-c",
    hex: "#919388",
  },
  {
    name: "416-c",
    hex: "#7e7f74",
  },
  {
    name: "417-c",
    hex: "#65665c",
  },
  {
    name: "418-c",
    hex: "#51534a",
  },
  {
    name: "419-c",
    hex: "#212322",
  },
  {
    name: "400-c",
    hex: "#c4bfb6",
  },
  {
    name: "401-c",
    hex: "#afa9a0",
  },
  {
    name: "402-c",
    hex: "#9d968d",
  },
  {
    name: "403-c",
    hex: "#8c857b",
  },
  {
    name: "404-c",
    hex: "#776e64",
  },
  {
    name: "405-c",
    hex: "#696158",
  },
  {
    name: "406-c",
    hex: "#c4bcb7",
  },
  {
    name: "407-c",
    hex: "#b2a8a2",
  },
  {
    name: "408-c",
    hex: "#978c87",
  },
  {
    name: "409-c",
    hex: "#857874",
  },
  {
    name: "410-c",
    hex: "#746661",
  },
  {
    name: "411-c",
    hex: "#5e514d",
  },
  {
    name: "412-c",
    hex: "#382f2d",
  },
  {
    name: "434-c",
    hex: "#d0c4c5",
  },
  {
    name: "435-c",
    hex: "#c1b2b6",
  },
  {
    name: "436-c",
    hex: "#ab989d",
  },
  {
    name: "437-c",
    hex: "#7b6469",
  },
  {
    name: "438-c",
    hex: "#584446",
  },
  {
    name: "439-c",
    hex: "#453536",
  },
  {
    name: "440-c",
    hex: "#382e2c",
  },
  {
    name: "warm-gray-1-c",
    hex: "#d7d2cb",
  },
  {
    name: "warm-gray-2-c",
    hex: "#cbc4bc",
  },
  {
    name: "warm-gray-3-c",
    hex: "#bfb8af",
  },
  {
    name: "warm-gray-4-c",
    hex: "#b6ada5",
  },
  {
    name: "warm-gray-5-c",
    hex: "#aca39a",
  },
  {
    name: "warm-gray-6-c",
    hex: "#a59c94",
  },
  {
    name: "warm-gray-7-c",
    hex: "#968c83",
  },
  {
    name: "warm-gray-8-c",
    hex: "#8c8279",
  },
  {
    name: "warm-gray-9-c",
    hex: "#83786f",
  },
  {
    name: "warm-gray-10-c",
    hex: "#796e65",
  },
  {
    name: "warm-gray-11-c",
    hex: "#6e6259",
  },
  {
    name: "cool-gray-1-c",
    hex: "#d9d9d6",
  },
  {
    name: "cool-gray-2-c",
    hex: "#d0d0ce",
  },
  {
    name: "cool-gray-3-c",
    hex: "#c8c9c7",
  },
  {
    name: "cool-gray-4-c",
    hex: "#bbbcbc",
  },
  {
    name: "cool-gray-5-c",
    hex: "#b1b3b3",
  },
  {
    name: "cool-gray-6-c",
    hex: "#a7a8aa",
  },
  {
    name: "cool-gray-7-c",
    hex: "#97999b",
  },
  {
    name: "cool-gray-8-c",
    hex: "#888b8d",
  },
  {
    name: "cool-gray-9-c",
    hex: "#75787b",
  },
  {
    name: "cool-gray-10-c",
    hex: "#63666a",
  },
  {
    name: "cool-gray-11-c",
    hex: "#53565a",
  },
  {
    name: "black-2-c",
    hex: "#332f21",
  },
  {
    name: "black-3-c",
    hex: "#212721",
  },
  {
    name: "black-4-c",
    hex: "#31261d",
  },
  {
    name: "black-5-c",
    hex: "#3e2b2e",
  },
  {
    name: "black-6-c",
    hex: "#101820",
  },
  {
    name: "black-7-c",
    hex: "#3d3935",
  },
  {
    name: "yellow-c",
    hex: "#fedd00",
  },
  {
    name: "yellow-012-c",
    hex: "#ffd700",
  },
  {
    name: "orange-021-c",
    hex: "#fe5000",
  },
  {
    name: "warm-red-c",
    hex: "#f9423a",
  },
  {
    name: "red-032-c",
    hex: "#ef3340",
  },
  {
    name: "rubine-red-c",
    hex: "#ce0058",
  },
  {
    name: "rhodamine-red-c",
    hex: "#e10098",
  },
  {
    name: "purple-c",
    hex: "#bb29bb",
  },
  {
    name: "violet-c",
    hex: "#440099",
  },
  {
    name: "blue-072-c",
    hex: "#10069f",
  },
  {
    name: "reflex-blue-c",
    hex: "#001489",
  },
  {
    name: "process-blue-c",
    hex: "#0085ca",
  },
  {
    name: "green-c",
    hex: "#00ab84",
  },
  {
    name: "black-c",
    hex: "#2d2926",
  },
  {
    name: "yellow-0131-c",
    hex: "#f2f0a1",
  },
  {
    name: "red-0331-c",
    hex: "#fcaebb",
  },
  {
    name: "magenta-0521-c",
    hex: "#f1b2dc",
  },
  {
    name: "violet-0631-c",
    hex: "#bf9bde",
  },
  {
    name: "blue-0821-c",
    hex: "#74d1ea",
  },
  {
    name: "green-0921-c",
    hex: "#9de7d7",
  },
  {
    name: "black-0961-c",
    hex: "#9e978e",
  },
  {
    name: "801-c",
    hex: "#009ace",
  },
  {
    name: "802-c",
    hex: "#44d62c",
  },
  {
    name: "803-c",
    hex: "#ffe900",
  },
  {
    name: "804-c",
    hex: "#ffaa4d",
  },
  {
    name: "805-c",
    hex: "#ff7276",
  },
  {
    name: "806-c",
    hex: "#ff3eb5",
  },
  {
    name: "807-c",
    hex: "#ea27c2",
  },
  {
    name: "871-c",
    hex: "#84754e",
  },
  {
    name: "872-c",
    hex: "#85714d",
  },
  {
    name: "873-c",
    hex: "#866d4b",
  },
  {
    name: "874-c",
    hex: "#8b6f4e",
  },
  {
    name: "875-c",
    hex: "#87674f",
  },
  {
    name: "876-c",
    hex: "#8b634b",
  },
  {
    name: "877-c",
    hex: "#8a8d8f",
  },
  {
    name: "medium-yellow-c",
    hex: "#ffd900",
  },
  {
    name: "bright-orange-c",
    hex: "#ff5e00",
  },
  {
    name: "bright-red-c",
    hex: "#f93822",
  },
  {
    name: "strong-red-c",
    hex: "#ce0056",
  },
  {
    name: "pink-c",
    hex: "#d62598",
  },
  {
    name: "medium-purple-c",
    hex: "#4e008e",
  },
  {
    name: "dark-blue-c",
    hex: "#00239c",
  },
  {
    name: "medium-blue-c",
    hex: "#0084ca",
  },
  {
    name: "bright-green-c",
    hex: "#00b08b",
  },
  {
    name: "neutral-black-c",
    hex: "#222223",
  },
];

const ColorPicker = ({ value, onChange, name }) => {
  const initialColor = colors.find(
    (element) => element.name === toTitleCase(value)
  ).hex;
  const [color, setColor] = React.useState(initialColor);
  const [prepColor, setPrepColor] = React.useState(initialColor);
  const [pantoneColor, setPantoneColor] = React.useState();
  const [tabValue, setTabValue] = React.useState("1");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Handle HEX Picker
  const handlePickerChange = (color) => {
    let colorList = colors;

    for (let i = 0; i < colorList.length; i++) {
      colorList[i].distance = colorDistance(color, colorList[i].hex);
    }
    const closestHex = findClosestColor(colorList).hex;

    setPrepColor(closestHex);
    setColor(closestHex);
    setPantoneColor(closestHex);
  };

  // Handle Prep Color Picker
  const handlePrepColorChange = (e, item) => {
    setPrepColor(item.props.value);
    setColor(item.props.value);

    let colorList = pantones;
    for (let i = 0; i < colorList.length; i++) {
      colorList[i].distance = colorDistance(item.props.value, colorList[i].hex);
    }

    setPantoneColor(findClosestColor(pantones).hex);

    onChange({
      target: {
        name,
        value: item.props.children,
      },
    });
  };

  // Handle Pantone Picker
  const handlePantoneColorChange = (e, item) => {
    let colorList = colors;
    for (let i = 0; i < colorList.length; i++) {
      colorList[i].distance = colorDistance(item.props.value, colorList[i].hex);
    }

    const closestHex = findClosestColor(colors).hex;
    setPantoneColor(item.props.value);
    setPrepColor(closestHex);
    setColor(closestHex);
  };

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
            >
              <Tab label="Prep Color" value="1" />
              <Tab label="HEX" value="2" />
              <Tab label="Pantone" value="3" />
            </TabList>
          </Box>
          <TabPanel sx={{ pt: 2, pr: 0, pl: 0 }} value="1">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Prep Color</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={prepColor}
                label="Prep Color"
                onChange={handlePrepColorChange}
              >
                {colors.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.hex}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </TabPanel>
          <TabPanel sx={{ pt: 2, pr: 0, pl: 0 }} value="2">
            <MuiColorInput
              fullWidth
              value={color}
              format="hex"
              onChange={handlePickerChange}
            />
          </TabPanel>
          <TabPanel sx={{ pt: 2, pr: 0, pl: 0 }} value="3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Pantone Coated
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pantoneColor}
                label="Pantone Coated"
                onChange={handlePantoneColorChange}
              >
                {pantones.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.hex}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default ColorPicker;
