import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDateRangePicker } from "@mui/x-date-pickers-pro/DesktopDateRangePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React from "react";

const DateRange = ({ dates, defaultDateRange, onChange }) => {
  return (
    <Grid item xs={12} md={12} lg={12}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDateRangePicker
          onChange={onChange}
          value={dates}
          defaultValue={defaultDateRange}
        />
      </LocalizationProvider>
    </Grid>
  );
};

export default DateRange;
