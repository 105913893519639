import MuiAlert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../Components/Common/Breadcrumbs";
import Table from "../Components/Common/Table";
import { JobColumns } from "../Components/Job/TableHeaders/JobColumns";
import { JobItemColumns } from "../Components/Job/TableHeaders/JobItemColumns";
import { AuthContext } from "../store/auth-context";

export default function JobItems() {
  const authCtx = useContext(AuthContext);
  const params = useParams();

  const [jobState, setJobState] = useState({
    isLoading: false,
    data: [],
  });

  const [jobItemsState, setJobItemsState] = useState({
    isLoading: false,
    data: [],
    total: 0,
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });

  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const fetchJobRow = useCallback(async () => {
    setJobState((previous) => ({ ...previous, isLoading: true }));
    try {
      const response = await authCtx.request(
        "GET",
        `${process.env.REACT_APP_Prep_Api_Host}job/${params.id}`
      );

      if (response && response.data) {
        const rowArray = [response.data];

        setJobState({
          isLoading: false,
          data: rowArray,
        });
      }
    } catch (error) {
      setOpen(true);
      setError(error);
      console.error(error);
    }
  }, [authCtx, paginationModel.page, paginationModel.pageSize, params.id]);

  const fetchJobItems = useCallback(async () => {
    setJobItemsState((previous) => ({ ...previous, isLoading: true }));
    try {
      const response = await authCtx.request(
        "GET",
        `${process.env.REACT_APP_Prep_Api_Host}jobitem/${params.id}/items`
      );

      if (response && response.data) {
        setJobItemsState((old) => ({
          ...old,
          isLoading: false,
          data: response.data,
          total: response.data.count,
        }));
      }
    } catch (error) {
      setOpen(true);
      setError(error);
      console.error(error);
    }
  }, [authCtx, paginationModel.page, paginationModel.pageSize, params.id]);

  useEffect(() => {
    const getJobRow = async () => {
      await fetchJobRow();
    };
    getJobRow();
  }, [fetchJobRow]);

  useEffect(() => {
    const getJobItems = async () => {
      await fetchJobItems();
    };
    getJobItems();
  }, [fetchJobItems]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <BreadCrumbs
            crumbs={[{ title: "Jobs", route: "/jobs" }, { title: params.id }]}
          ></BreadCrumbs>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  pt: 0,
                  pb: 3,
                }}
                variant="h4"
              >
                Items for JobID {params.id}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 3,
                mb: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Table
                data={jobState.data}
                isLoading={jobState.isLoading}
                columns={JobColumns}
                disablePaging={true}
                pagination={[]}
                pageSizeOptions={[]}
                density="standard"
                columnVisibilityModel={{
                  id: false,
                  tools: false,
                }}
              />
            </Paper>
            <Paper
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Table
                data={jobItemsState.data}
                rowCount={jobItemsState.total}
                isLoading={jobItemsState.isLoading}
                columns={JobItemColumns}
                disablePaging={true}
                pagination={[]}
                pageSizeOptions={[]}
                density="standard"
                columnVisibilityModel={{
                  id: false,
                }}
              />
            </Paper>
          </Grid>
        </Grid>
        {error && (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{ width: "100%" }}
            >
              {error.message}
            </Alert>
          </Snackbar>
        )}
      </Grid>
    </>
  );
}
