import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/auth-context";

const CountriesDropdown = ({ onChange }) => {
  const authCtx = useContext(AuthContext);
  const [countriesState, setCountriesState] = useState({
    isLoading: false,
    data: [],
  });

  const handleStateChange = (e) => {
    onChange(e);
  };

  const fetchTypes = useCallback(async () => {
    const response = await authCtx.request(
      "GET",
      `${process.env.REACT_APP_Prep_Api_Host}v1/utility/country-list`
    );

    if (response && response.data) {
      setCountriesState((previous) => ({ ...previous, data: response.data }));
    }
  }, [authCtx]);

  const getFlagEmoji = (countryCode) => {
    const codePoints = countryCode
      .toUpperCase()
      .split("")
      .map((char) => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  };

  useEffect(() => {
    const getTypes = async () => {
      await fetchTypes();
    };
    getTypes();
  }, [fetchTypes]);

  return (
    <FormControl fullWidth>
      <InputLabel id="store-type-select-label">Country</InputLabel>
      <Select
        labelId="store-type-label"
        id="store-type-select"
        label="Type"
        name="country"
        onChange={handleStateChange}
        defaultValue="US"
      >
        {countriesState.data.map((type, index) => (
          <MenuItem key={index} value={type.countryCode}>
            {getFlagEmoji(type.countryCode)} {type.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CountriesDropdown;
