import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import CountriesDropdown from "../Common/CountriesDropdown";
import StatesDropdown from "../Common/StatesDropdown";

const Address = ({ address, onChange }) => {
  const handleAddressChange = (e) => {
    onChange(e);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="h5" gutterBottom>
          Address
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          placeholder="Address 1"
          fullWidth
          required
          id="outlined-required"
          name="address1"
          label="Address 1"
          onChange={handleAddressChange}
          value={address.address1}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          placeholder="Unit #, P"
          fullWidth
          id="outlined-required"
          name="address2"
          label="Address 2"
          onChange={handleAddressChange}
          value={address.address2}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TextField
          placeholder="City"
          fullWidth
          required
          id="outlined-required"
          label="City"
          name="city"
          onChange={handleAddressChange}
          value={address.city}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <StatesDropdown
          onChange={handleAddressChange}
          value={address.state}
        ></StatesDropdown>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TextField
          placeholder="Postal Code"
          fullWidth
          required
          id="outlined-required"
          label="Postal Code"
          name="zip"
          onChange={handleAddressChange}
          value={address.zip}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <CountriesDropdown
          onChange={handleAddressChange}
          value={address.country}
        ></CountriesDropdown>
      </Grid>
    </Grid>
  );
};

Address.propTypes = {
  address: PropTypes.object,
};

export default Address;
