import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../Components/Common/Breadcrumbs";
import { AuthContext } from "../store/auth-context";

export default function ProductDetail() {
  const params = useParams();
  const authCtx = useContext(AuthContext);
  const inputRef = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [tabValue, setTabValue] = React.useState("1");
  const [product, setProduct] = React.useState();
  const [metaTitle, setmetaTitle] = React.useState();
  const [metaKeywords, setmetaKeywords] = React.useState();
  const [metaDescription, setmetaDescription] = React.useState();

  const insertAtCursor = (input, textToInsert) => {
    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;
    const beforeValue = input.value.substring(0, startPos);
    const afterValue = input.value.substring(endPos, input.value.length);
    input.value = beforeValue + textToInsert + afterValue;
    input.selectionStart = input.selectionEnd = startPos + textToInsert.length;
    input.focus();
  };

  const handleVariableInsertion = (e, variable) => {
    insertAtCursor(inputRef.current, variable);
    if (inputRef.current.id === "metaTitle") {
      setmetaTitle(inputRef.current.value);
    } else if (inputRef.current.id === "metaKeywords") {
      setmetaKeywords(inputRef.current.value);
    } else if (inputRef.current.id === "metaDescription") {
      setmetaDescription(inputRef.current.value);
    }
  };

  const handleTitleChange = (e) => {
    setmetaTitle(e.target.value);
  };
  const handleKeywordsChange = (e) => {
    setmetaKeywords(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setmetaDescription(e.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const crumbs = [
    {
      title: "Products",
      route: "/products",
    },
    {
      title: "Edit",
    },
  ];

  const handleDialogClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleSeoSaveClick = (event, newValue) => {
    if (!metaTitle || !metaKeywords || !metaDescription) {
      handleDialogClickOpen();
    }
    handleSave();
  };

  const handleSave = async (event, newValue) => {
    const results = await authCtx.request(
      "PATCH",
      `${process.env.REACT_APP_Prep_Api_Host}product/`,
      {
        ...product,
        marketing: {
          ...product.marketing,
          contentType: 2, // product
          itemId: params.id,
          keywords: metaKeywords,
          title: metaTitle,
          description: metaDescription,
        },
      }
    );
  };

  useEffect(() => {
    async function getProduct() {
      // If there are no search params, there is no need to search
      const productData = await authCtx.request(
        "GET",
        `${process.env.REACT_APP_Prep_Api_Host}product/${params.id}`
      );

      if (productData) {
        setProduct(productData.data);
        setmetaTitle(productData?.data?.marketing?.title);
        setmetaKeywords(productData?.data?.marketing?.keywords);
        setmetaDescription(productData?.data?.marketing?.description);
      }
    }
    getProduct();
  }, [authCtx, params.id]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <BreadCrumbs crumbs={crumbs}>
            <Typography color="text.primary">Breadcrumbs</Typography>
          </BreadCrumbs>
          {product && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  sx={{
                    pt: 0,
                    pb: 3,
                  }}
                  variant="h4"
                >
                  ({product.sku}){" "}
                  {product.name
                    .replace("<NameForPrint>", "")
                    .replace("<mascot>", "")}
                </Typography>

                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleTabChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="SEO" value="1" />
                    </TabList>
                  </Box>
                  <TabPanel value="1" sx={{ p: 0 }}>
                    <Paper
                      sx={{
                        p: 3,
                        mb: 3,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Stack direction="row" spacing={1}>
                            <Chip
                              label="Store Name"
                              variant="outlined"
                              onClick={(e) => {
                                handleVariableInsertion(e, "<school name>");
                              }}
                            />
                            <Chip
                              label="Store Print Name"
                              variant="outlined"
                              onClick={(e) => {
                                handleVariableInsertion(e, "<name for print>");
                              }}
                            />
                            <Chip
                              label="Store City"
                              variant="outlined"
                              onClick={(e) => {
                                handleVariableInsertion(e, "<store_city>");
                              }}
                            />
                            <Chip
                              label="Store State"
                              variant="outlined"
                              onClick={(e) => {
                                handleVariableInsertion(e, "<store_state>");
                              }}
                            />
                            <Chip
                              label="Store Zip"
                              variant="outlined"
                              onClick={(e) => {
                                handleVariableInsertion(e, "<store_zip>");
                              }}
                            />
                            <Chip
                              label="Mascot"
                              variant="outlined"
                              onClick={(e) => {
                                handleVariableInsertion(e, "<mascot>");
                              }}
                            />
                          </Stack>
                          <Typography
                            variant="caption"
                            gutterBottom
                            sx={{ display: "block", mt: 1 }}
                          >
                            Click to insert variable substitution at your
                            cursor.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <TextField
                            placeholder=""
                            fullWidth
                            required
                            id="metaTitle"
                            label="Meta Title"
                            name="name"
                            value={metaTitle}
                            onChange={handleTitleChange}
                            onClick={() =>
                              (inputRef.current =
                                document.getElementById("metaTitle"))
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <TextField
                            placeholder=""
                            fullWidth
                            required
                            id="metaKeywords"
                            label="Meta Keywords"
                            name="name"
                            value={metaKeywords}
                            onChange={handleKeywordsChange}
                            onClick={() =>
                              (inputRef.current =
                                document.getElementById("metaKeywords"))
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <TextField
                            placeholder=""
                            fullWidth
                            multiline
                            rows={4}
                            required
                            id="metaDescription"
                            label="Meta Description"
                            name="name"
                            value={metaDescription}
                            onChange={handleDescriptionChange}
                            onClick={() =>
                              (inputRef.current =
                                document.getElementById("metaDescription"))
                            }
                            helperText={
                              <Box
                                component="span"
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>
                                  Google's recommendation is to keep meta
                                  descriptions between 50–160 characters.
                                </span>

                                <span>
                                  {`${
                                    metaDescription ? metaDescription.length : 0
                                  } / ${160}`}
                                </span>
                              </Box>
                            }
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </TabPanel>
                  <TabPanel value="2" sx={{ p: 0 }}>
                    <Paper
                      sx={{
                        p: 3,
                        mb: 3,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="h5" gutterBottom>
                            Designs
                          </Typography>
                          <p>To be implemented. </p>
                        </Grid>
                      </Grid>
                    </Paper>
                  </TabPanel>
                  <TabPanel value="3" sx={{ p: 0 }}>
                    <Paper
                      sx={{
                        p: 3,
                        mb: 3,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="h5" gutterBottom>
                            Licensing
                          </Typography>
                          <p>To be implemented. </p>
                        </Grid>
                      </Grid>
                    </Paper>
                  </TabPanel>
                </TabContext>
                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction="row" spacing={2}>
                    <LoadingButton
                      loadingIndicator="Saving…"
                      size="large"
                      variant="contained"
                      onClick={handleSeoSaveClick}
                      startIcon={<SaveIcon />}
                    >
                      Update Product
                    </LoadingButton>
                    <Button size="large" variant="text" href="#/products">
                      Cancel
                    </Button>
                  </Stack>
                  <Typography
                    sx={{
                      pt: 3,
                      pb: 3,
                    }}
                    variant="caption"
                    display="block"
                    gutterBottom
                  ></Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Empty Meta Fields</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If no value is provided in any of the fields below the system will
            generate a default value for the meta element even though one is not
            shown.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSave} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
