import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/auth-context";

const StoreTypes = ({ value, onChange }) => {
  const authCtx = useContext(AuthContext);
  const [storeTypesState, setStoreTypesState] = useState({
    isLoading: false,
    data: [],
  });

  const fetchTypes = useCallback(async () => {
    const response = await authCtx.request(
      "GET",
      `${process.env.REACT_APP_Prep_Api_Host}store/types`
    );

    if (response && response.data) {
      setStoreTypesState((previous) => ({ ...previous, data: response.data }));
    }
  }, [authCtx]);

  useEffect(() => {
    const getTypes = async () => {
      await fetchTypes();
    };
    getTypes();
  }, [fetchTypes]);

  return (
    <FormControl fullWidth>
      <InputLabel id="store-type-select-label">Type</InputLabel>
      <Select
        labelId="store-type-label"
        id="store-type-select"
        onChange={onChange}
        label="Type"
        value={value}
      >
        {storeTypesState.data.map((type, index) => (
          <MenuItem key={index} value={type.id}>
            {type.name.replace("_", " ")}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StoreTypes;
