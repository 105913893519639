import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Address from "../Components/Common/AddressEdit";
import BreadCrumbs from "../Components/Common/Breadcrumbs";
import ColorPicker from "../Components/Common/ColorPicker";
import StoreTypesDropdown from "../Components/Common/StoreTypesDropDown";
import { AuthContext } from "../store/auth-context";

const styles = {
  updated: {
    color: "rgb(170 170 170)",
  },
  border: {
    border: "1px solid #bda98d",
  },
};

export default function StoreDetail() {
  const params = useParams();
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [store, setStore] = React.useState(null);
  const [storeConfiguration, setStoreConfiguration] = React.useState(null);
  const [storeSettings, setStoreSettings] = React.useState(null);
  const [storeAddress, setStoreAddress] = React.useState(null);
  const [storeType, setStoreType] = React.useState("");
  const [tabValue, setTabValue] = React.useState("1");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleStoreTypeChange = (e, y) => {
    setStoreType(e.target.value);
  };

  const handleButtonEnable = async () => {
    setStoreSettings(() => ({
      ...storeSettings,
      enabled: true,
    }));
  };

  const handlestoreConfigurationChange = (e) => {
    setStoreConfiguration(() => ({
      ...storeConfiguration,
      [e.target.name]: e.target.value,
    }));
  };

  const handleStoreSettingsSwitchChange = (e, inverse) => {
    setStoreSettings(() => ({
      ...storeSettings,
      [e.target.name]: inverse ? !e.target.checked : e.target.checked,
    }));
  };

  const handlestoreAddressChange = (e) => {
    setStoreAddress(() => ({
      ...storeAddress,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSaveStore = async (e) => {
    await saveStore;
  };

  const saveStore = async function () {
    setIsSaving(true);
    const results = await authCtx.request(
      "PATCH",
      `${process.env.REACT_APP_Prep_Api_Host}store/${store.id}`,
      {
        ...store,
        Configuration: storeConfiguration,
        Settings: storeSettings,
        StoreAddress: storeAddress,
        Type: storeType,
      }
    );
    setIsSaving(false);
  };

  useEffect(() => {
    async function getStore() {
      setIsLoading(true);
      // If there are no search params, there is no need to search
      const storeData = await authCtx.request(
        "GET",
        `${process.env.REACT_APP_Prep_Api_Host}store/${params.id}`
      );

      if (storeData) {
        setStore({
          id: storeData.data.id,
          createDate: storeData.data.createDate,
          updatedDate: storeData.data.updatedDate,
        });
        setStoreConfiguration(storeData.data.configuration);
        setStoreSettings(storeData.data.settings);
        setStoreAddress(storeData.data.storeAddress);
        setStoreType(storeData.data.type);
      }
      setIsLoading(false);
    }
    getStore();
  }, [authCtx, params.id]);

  const crumbs = [
    {
      title: "Stores",
      route: "/stores",
    },
    {
      title: "Edit",
    },
  ];

  return (
    <>
      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <BreadCrumbs crumbs={crumbs}>
            <Typography color="text.primary">Breadcrumbs</Typography>
          </BreadCrumbs>

          <Grid container spacing={3}>
            {store && (
              <Grid item xs={12} md={12} lg={12}>
                {!storeSettings.enabled && (
                  <Alert
                    variant="outlined"
                    severity="warning"
                    sx={{
                      mb: 4,
                    }}
                  >
                    <AlertTitle>
                      <strong>Warning</strong>
                    </AlertTitle>
                    <p>
                      This store is disabled and is not accessible to customers.
                      Would you like to enable this store now? <br />
                      <em>
                        You will still need to click the save button for the
                        changes to take effect.
                      </em>
                    </p>
                    <Button
                      onClick={handleButtonEnable}
                      variant="outlined"
                      color="warning"
                    >
                      Enable Store
                    </Button>
                  </Alert>
                )}
                <Typography
                  sx={{
                    pt: 0,
                    pb: 0,
                  }}
                  variant="h4"
                  display="block"
                  gutterBottom
                >
                  {storeConfiguration.name}
                </Typography>
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleTabChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="General" value="1" />
                      <Tab disabled label="Designs" value="2" />
                      <Tab disabled label="Licensing" value="3" />
                    </TabList>
                  </Box>
                  <TabPanel value="1" sx={{ p: 0 }}>
                    <Paper
                      sx={{
                        p: 3,
                        mb: 3,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="h5" gutterBottom>
                            Details
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <TextField
                            placeholder="Store name"
                            fullWidth
                            required
                            id="storeName"
                            label="Store name"
                            onChange={handlestoreConfigurationChange}
                            value={storeConfiguration.name}
                            name="name"
                            helperText="Store name used when navigating the site"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <TextField
                            placeholder="Print Name"
                            fullWidth
                            required
                            id="printName"
                            label="Print Name"
                            onChange={handlestoreConfigurationChange}
                            value={storeConfiguration.nameForPrint}
                            name="nameForPrint"
                            helperText="Store name used on product embelishments"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <TextField
                            placeholder="Horned Frogs"
                            fullWidth
                            required
                            onChange={handlestoreConfigurationChange}
                            value={storeConfiguration.mascot}
                            name="mascot"
                            id="mascot"
                            label="Mascot"
                            helperText="Store mascot. Used in many designs."
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <StoreTypesDropdown
                            onChange={handleStoreTypeChange}
                            value={storeType}
                          />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}></Grid>
                      </Grid>
                    </Paper>
                    <Paper
                      sx={{
                        p: 3,
                        mb: 3,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Address
                        onChange={handlestoreAddressChange}
                        address={storeAddress}
                      ></Address>
                    </Paper>
                    <Paper
                      sx={{
                        p: 3,
                        mb: 3,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="h5" gutterBottom>
                            Configuration
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={6} md={6} lg={6}>
                              <Grid item xs={12} md={12} lg={12}>
                                <Typography
                                  sx={{ pt: 1.5 }}
                                  variant="subtitle1"
                                >
                                  Short Abbreviation
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                  fullWidth
                                  required
                                  onChange={handlestoreConfigurationChange}
                                  name="shortAbbreviation"
                                  value={storeConfiguration.shortAbbreviation}
                                  id="outlined-required"
                                  placeholder="JH"
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                              <Grid item xs={12} md={12} lg={12}>
                                <Typography
                                  sx={{ pt: 1.5 }}
                                  variant="subtitle1"
                                >
                                  Long Abbreviation
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                  fullWidth
                                  required
                                  onChange={handlestoreConfigurationChange}
                                  name="longAbbreviation"
                                  value={storeConfiguration.longAbbreviation}
                                  id="outlined-required"
                                  placeholder="JHS"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={4}>
                              <Grid item xs={12} md={4} lg={4}>
                                <Typography
                                  sx={{ pt: 1.5 }}
                                  variant="subtitle1"
                                >
                                  Color 1
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12}>
                                <ColorPicker
                                  onChange={handlestoreConfigurationChange}
                                  name="color1"
                                  value={storeConfiguration.color1}
                                ></ColorPicker>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={4}>
                              <Grid item xs={12} md={12} lg={12}>
                                <Typography
                                  sx={{ pt: 1.5 }}
                                  variant="subtitle1"
                                >
                                  Color 2
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12}>
                                <ColorPicker
                                  onChange={handlestoreConfigurationChange}
                                  name="color2"
                                  value={storeConfiguration.color2}
                                ></ColorPicker>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={4}>
                              <Grid item xs={6} md={3} lg={3}>
                                <Typography
                                  sx={{ pt: 1.5 }}
                                  variant="subtitle1"
                                >
                                  Color 3
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12}>
                                <ColorPicker
                                  onChange={handlestoreConfigurationChange}
                                  name="color3"
                                  value={storeConfiguration.color3}
                                ></ColorPicker>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1}></Grid>
                      </Grid>
                    </Paper>
                    <Paper
                      sx={{
                        p: 3,
                        mb: 3,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="h5" gutterBottom>
                            Settings
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          md={9}
                          lg={9}
                          sx={{
                            p: 2,
                            borderBottom: 1,
                            borderColor: "grey.300",
                          }}
                        >
                          <Typography variant="subtitle1" gutterBottom>
                            <strong>Enabled</strong>
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            The store is active on the site allowing customers
                            to purchase products from this store.
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          md={3}
                          lg={3}
                          sx={{
                            p: 2,
                            borderBottom: 1,
                            borderColor: "grey.300",
                            textAlign: "right",
                          }}
                        >
                          <p>{storeSettings.enabled}</p>
                          <Box display="flex" justifyContent="flex-end">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    defaultChecked
                                    onChange={(e) => {
                                      handleStoreSettingsSwitchChange(e, false);
                                    }}
                                    name="enabled"
                                    value={storeSettings.enabled}
                                    checked={storeSettings.enabled}
                                  />
                                }
                              />
                            </FormGroup>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          md={9}
                          lg={9}
                          sx={{
                            p: 2,
                            borderBottom: 1,
                            borderColor: "grey.300",
                          }}
                        >
                          <Typography variant="subtitle1" gutterBottom>
                            <strong>Show in Site Search</strong>
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            Allow the store to be found using the site's own
                            search functionality.
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          md={3}
                          lg={3}
                          sx={{
                            p: 2,
                            borderBottom: 1,
                            borderColor: "grey.300",
                          }}
                        >
                          <Box display="flex" justifyContent="flex-end">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    defaultChecked
                                    onChange={(e) => {
                                      handleStoreSettingsSwitchChange(e, true);
                                    }}
                                    name="hideFromSearch"
                                    checked={!storeSettings.hideFromSearch}
                                  />
                                }
                              />
                            </FormGroup>
                          </Box>
                        </Grid>
                        <Grid item xs={9} md={9} lg={9} sx={{ p: 2 }}>
                          <Typography variant="subtitle1" gutterBottom>
                            <strong>Search Engine Crawling</strong>
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            Allow search engines to crawl and index this store.
                          </Typography>
                        </Grid>
                        <Grid item xs={3} md={3} lg={3} sx={{ p: 2 }}>
                          <Box display="flex" justifyContent="flex-end">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    onChange={(e) => {
                                      handleStoreSettingsSwitchChange(e, true);
                                    }}
                                    name="doNotIndex"
                                    slotProps={{ inverse: true }}
                                    checked={!storeSettings.doNotIndex}
                                  />
                                }
                              />
                            </FormGroup>
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                  </TabPanel>
                  <TabPanel value="2" sx={{ p: 0 }}>
                    <Paper
                      sx={{
                        p: 3,
                        mb: 3,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="h5" gutterBottom>
                            Designs
                          </Typography>
                          <p>To be implemented. </p>
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}></Grid>
                      </Grid>
                    </Paper>
                  </TabPanel>
                  <TabPanel value="3" sx={{ p: 0 }}>
                    <Paper
                      sx={{
                        p: 3,
                        mb: 3,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography variant="h5" gutterBottom>
                            Licensing
                          </Typography>
                          <p>To be implemented. </p>
                        </Grid>

                        <Grid item xs={6} md={6} lg={6}></Grid>
                      </Grid>
                    </Paper>
                  </TabPanel>
                </TabContext>

                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction="row" spacing={2}>
                    <LoadingButton
                      loading={isSaving}
                      loadingIndicator="Saving…"
                      onClick={saveStore}
                      size="large"
                      variant="contained"
                      startIcon={<SaveIcon />}
                    >
                      Update Store
                    </LoadingButton>
                    <Button size="large" variant="text" href="#/stores">
                      Cancel
                    </Button>
                  </Stack>
                  <Typography
                    style={styles.updated}
                    sx={{
                      pt: 3,
                      pb: 3,
                    }}
                    variant="caption"
                    display="block"
                    gutterBottom
                  >
                    Last Updated:{" "}
                    {moment(store.updatedDate).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                    <br />
                    Created:{" "}
                    {moment(store.createDate).format("MMMM Do YYYY, h:mm:ss a")}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
